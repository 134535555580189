import React, { useEffect, useState } from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import About from "../components/sections/About";
import Blogs from "../components/sections/Blogs";
import Contact from "../components/sections/Contact";
import Experiences from "../components/sections/Experiences";
import Herosection from "../components/sections/Herosection";
import { Helmet } from "react-helmet";
import Jobs from "../components/sections/Jobs";
import Pricing from "../components/sections/Pricing";
import Landing from "../components/sections/Landing";
import Services from '../components/sections/Services';
import Testimonials from '../components/sections/Testimonials';
import Brandlogos from '../components/sections/Brandlogos';
import Gallery from "../components/sections/Gallery";
import Share from "../components/sections/Share";
import Links from "../components/sections/Links";
import Subscribe from "../components/sections/Subscribe";
import Support from "../components/sections/Support";

function Page({ data, toggleEditor, isEditor }) {
  const { sectionOrder } = data;
  const [toggleMenu, setToggleMenu] = useState(false);
  const [page, setPage] = useState();

  useEffect(() => {
    const orderedPage = sectionOrder.map((sectionName, index) => {
      return (
        <Element key={index} name={`section-${sectionName}`}>
          {whichSection(sectionName)}
        </Element>
      );
    })
    setPage(orderedPage);
  }, [sectionOrder, data])

  const whichSection = (sectionName) => {
    switch (sectionName) {
      case 'landing':
        return data.isLandingShown &&
          <Landing data={data} />
      case 'home':
        return data.isHeaderShown &&
          <ReactCursorPosition>
            <Herosection data={data} />
          </ReactCursorPosition>
      case 'about':
        return data.isAboutShown &&
          <About data={data} />
      case 'services':
        return data.isServicesShown &&
          <Services data={data} />
      case 'experiences':
        return data.isExperienceShown &&
          <Experiences data={data} />
      case 'works':
        return data.isPortfolioShown &&
          <Jobs data={data} />
      case 'pricing':
        return data.isPricingShown &&
          <Pricing data={data} />
      case 'testimonials':
        return data.isTestimonialShown &&
          <Testimonials data={data} />
      case 'brandlogos':
        return data.isBrandLogosShown &&
          <Brandlogos data={data} />
      case 'posts':
        return data.isBlogShown &&
          <Blogs data={data} />
      case 'gallery':
        return data.isGalleryShown &&
          <Gallery data={data} />
      case 'contact':
        return data.isContactShown &&
          <Contact data={data} />
      case 'share':
        return data.isShareShown &&
            <Share data={data} />
      case 'links':
        return data.isLinksShown &&
            <Links data={data} />
      case 'subscribe':
        return data.isSubscribeShown &&
            <Subscribe data={data} />
      case 'support':
        return data.isSupportShown &&
            <Support data={data} />
      default:
        break;
    }
  }

  // const headerToggler = (e) => {
  //   e.preventDefault();
  //   setToggleMenu(!toggleMenu);
  // };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  useEffect(() => {
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = data.favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [data])

  return (
    <div className={isEditor ? toggleEditor ? 'page-mid' : 'page-full' : ''}>
      <Helmet>
        <title>{data.pageTitle}</title>
      </Helmet>
      {page}
      <div className="spacer" data-height="96"></div>
    </div>
  );
}

export default Page;
