import React from "react";
import ScrollAnimation from "react-animate-on-scroll";



function NotFound() {
    return <div className='flex-center'>
        <ScrollAnimation
            animateIn="fadeInDown"
            animateOut="fadeInOut"
            animateOnce={true}
        >
            <h2 className='mb-0'>Sorry!</h2>
            <h2 className='mb-3'> The page you're looking for was not found!</h2>
        </ScrollAnimation>
        <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
        >
            <img src='images/service-2.svg' alt='' style={{ height: '40vh', width: 'auto' }} />
            <p className='mt-3'><a href='/'>Click here</a> to go back to the landing page.</p>
        </ScrollAnimation>
    </div>
}

export default NotFound;


