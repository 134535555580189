import React, { useState, useEffect } from "react";
import Header from "../components/layouts/Header";
import Blog from "../components/elements/Blog";
import Pagination from "../components/elements/Pagination";
import firebase from "firebase";
import { useParams } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { defaultData } from "../defaultData";

function Bloglist(props) {
  // document.body.classList.add("dark");
  //Uncomment the above line if you use dark version

  const [posts, setPosts] = useState([]);
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const [currentPosts, setCurrentPosts] = useState([]);

  const firestore = firebase.firestore();
  const pagesRef = firestore.collection('pages');
  let { profileId } = useParams();
  let query = pagesRef.where('id', '==', profileId ? profileId : process.env.REACT_APP_FIREBASE_PAGE_ID);
  const [pages] = useCollectionData(query);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  useEffect(() => {
    if (pages && profileId === 'submit') {
      setData(defaultData);
      setPosts(defaultData.allBlogs);
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = pages[0].favicon;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    else if (pages) {
      setPosts(pages[0].allBlogs);
      setData(pages[0]);
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = pages[0].favicon;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }, [profileId, pages]);

  useEffect(() => {
    if (posts) {
      setCurrentPosts(posts.slice(indexOfFirstPost, indexOfLastPost));
    }
  }, [posts, indexOfFirstPost, indexOfLastPost]);

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  return (
    <>
      <Header
        isBlog
        data={data}
        logoSource="Posts"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container">
            <div className="row blog-wrapper fix-spacing">
              {currentPosts.map((blogItem) => (
                <div className="col-md-6" key={blogItem.id}>
                  <Blog data={data} blogData={blogItem} />
                </div>
              ))}
            </div>
            <div className="spacer" data-height="50"></div>
            {!(posts && posts.length > postsPerPage) ? null : (
              <Pagination
                itemsPerPage={postsPerPage}
                totalItems={posts.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default Bloglist;
