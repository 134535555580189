import Favicon from './assets/favicon.ico'

const links = {
    card1: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/3264635.jpg?alt=media&token=346adb1c-815a-4cea-9e6a-74d3856ca83a',
    card2: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/default%2Fimages%2Fcards%2Fcard-2.svg?alt=media&token=6abffa40-7a44-440a-8a91-73089cc17795',
    card3: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/default%2Fimages%2Fcards%2Fcard-3.svg?alt=media&token=8d19390f-f0bc-4e11-8a89-5e04b3d1ee98',
    avatar1: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/circle-cropped%20(1).png?alt=media&token=4e61779e-3c34-44e5-a988-536a75834561',
    works1: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/1.svg?alt=media&token=ecb19310-f44f-4770-a0b4-30b7637d130d',
    works2: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/2.svg?alt=media&token=117e68b5-cf54-454d-8086-55106b827e8b',
    works3: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/3.svg?alt=media&token=1c2b97d3-0f30-451c-bf7e-ad8ce1a6f71a',
    works4: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/4.svg?alt=media&token=593a8065-598f-4061-97d7-8f906593ce1e',
    works5: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/5.svg?alt=media&token=7a8d5c01-3f65-4898-93af-b6a3677a0e23',
    works6: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/6.svg?alt=media&token=c6395410-d4c8-43b1-91a4-137d5f6f4688',
    price1: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/price-1.svg?alt=media&token=37b8adbc-d678-4b04-8fbd-4ac73c7f1978',
    price2: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/price-2.svg?alt=media&token=f9fe55a3-b597-426a-aec1-35d79c361376',
    price3: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/price-3.svg?alt=media&token=76b0bcfc-a59d-4f76-88a3-579e78aabdfb',
    landing: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/undraw_experience_design_eq3j.svg?alt=media&token=2db3220b-de79-4e3e-a632-8fac49b3e3ab'
}

export const defaultData = {
    sectionOrder: [
        'home',
        'landing',
        'links',
        'about',
        'experiences',
        'works',
        'services',
        'pricing',
        'testimonials',
        'brandlogos',
        'posts',
        'gallery',
        'support',
        'contact',
        'subscribe',
        'share',
    ],
    userId: '',
    id: '',
    pageTitle: 'John Smith',
    favicon: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/favicon.ico?alt=media&token=07f9d75d-8701-469b-98aa-d7e96d5eaefd',
    pageId: '',
    isLandingShown: true,
    landingImage: links.landing,
    landingButtonText: 'Create a Website',
    landingButtonLink: '/submit',
    landingSecondButtonText: 'See Demo Website',
    landingSecondgButtonLink: '/johnsmith',
    landingButtonUrl: '',
    landingTitle: 'Create Your Dream Website Fast & Easy!',
    landingSubText: `Choose the widgets you want, fill texts, images and links and your website is ready.`,
    cards: [
        {
            id: 1,
            title: 'Fast & Easy',
            icon: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/3264635.jpg?alt=media&token=346adb1c-815a-4cea-9e6a-74d3856ca83a',
            content: 'A Simple option but powerful to create your website, no technical or design knowledge needed.',
        },
        {
            id: 2,
            title: 'Big Library of Widgets',
            icon: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/20945167.jpg?alt=media&token=660f4079-3d4c-44b1-8950-723048e46446',
            content: 'Add different widgets to your website depend on your website needs.',
        },
        {
            id: 3,
            title: 'Free Hosting',
            icon: 'https://firebasestorage.googleapis.com/v0/b/builder-4ccd5.appspot.com/o/3066886.jpg?alt=media&token=692eaffa-9daa-4eee-b3b8-62ed72864481',
            content: 'Deploy your websites with ease of mind knowing we take care of everything.',
        }
    ],
    isHeaderShown: true,
    headerButtonText: 'Partner With Me',
    headerButtonLink: 'contact',
    headerButtonUrl: '',
    firstName: 'John',
    lastName: 'Smith',
    profileImage: links.avatar1,
    titles: [
        {
            id: 1,
            text: "Graphic Designer"
        },
        {
            id: 2,
            text: "Photographer"
        },
        {
            id: 3,
            text: "Web Designer"
        },
        {
            id: 4,
            text: "Consultant"
        },
    ],
    isSmallLinks: true,
    links: [
        { platform: 'GitHub', url: 'https://www.linkedin.com/' },
        { platform: 'LinkedIn', url: 'https://github.com/' },
        { platform: 'Website', url: 'https://example.com' },
        { platform: 'Email', url: 'namenamename@gmail.com' },
        { platform: 'Instagram', url: 'https://www.instagram.com/' },
        { platform: 'Facebook', url: 'https://www.facebook.com/' },
        { platform: 'Twitter', url: 'https://twitter.com/' }
    ],
    isAboutShown: true,
    aboutTitle: 'About Me',
    intro: 'Freelance Graphic Designer, Photographer and Web Designer with 12 years of experience. I will create any design your company needs. Check out my portfolio to see my works and contact me for  more information. I will do my best efforts to create your the design you wish for a fair price.',
    cvLink: 'https://example.com/',
    profileImageAbout: links.avatar1,
    downloadCV: 'Download CV',
    bars: [
        {
            id: 1,
            title: "Graphic Design",
            percentage: 85,
            progressColor: "#FFD15C",
        },
        {
            id: 2,
            title: "Photography",
            percentage: 95,
            progressColor: "#FF4C60",
        },
        {
            id: 3,
            title: "Web Design",
            percentage: 80,
            progressColor: "#6C6CE5",
        }
    ],
    isServicesShown: true,
    servicesTitle: 'Services',
    servicesData: [
        {
            id: 1,
            icon: "images/service-1.svg",
            title: "UI/UX design",
            content:
                "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
            color: "#6C6CE5",
            contentColor: "light",
        },
        {
            id: 2,
            icon: "images/service-2.svg",
            title: "Web Development",
            content:
                "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
            color: "#F9D74C",
            contentColor: "dark",
        },
        {
            id: 3,
            icon: "images/service-3.svg",
            title: "Photography",
            content:
                "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
            color: "#F97B8B",
            contentColor: "light",
        },
    ],
    isExperienceShown: true,
    experienceTitle: 'Experience',
    educationData: [
        {
            id: 1,
            title: "Oxford High School ",
            years: "2000 - 2003",
            content:
                "Computer science.",
        },
        {
            id: 2,
            title: "The Royal College of Art & Design",
            years: "2003 - 2006",
            content:
                "Computer science.",
        },
    ],
    experienceData: [
        {
            id: 1,
            title: "Web Designer",
            years: "2015 - Present",
            content:
                "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
        },
        {
            id: 2,
            title: "Photographer",
            years: "2015 - 2011",
            content:
                "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
        },
        {
            id: 3,
            title: "Freelance Graphic Designer",
            years: "2011 - 2007",
            content:
                "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
        },
    ],
    isPortfolioShown: true,
    portfolioTitle: 'Recent Works',
    works: [
        {
            id: 3,
            title: "Lecturer",
            category: "branding",
            image: links.works3,
            text: "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
            video: "https://www.youtube.com/watch?v=IZRduzCkaA0&ab_channel=welcometothebestplace",
            linkText: "good link",
        },
        {
            id: 4,
            title: "Onboarding Motivation",
            category: "Content I Made",
            image: links.works4,
            text: "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
            video: "https://www.youtube.com/watch?v=IZRduzCkaA0&ab_channel=welcometothebestplace",
            linkText: "good link",
        },
        {
            id: 5,
            title: "iMac Mockup Design",
            category: "art",
            image: links.works5,
            text: "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
            video: "https://www.youtube.com/watch?v=IZRduzCkaA0&ab_channel=welcometothebestplace",
            linkText: "good link",
        },
        {
            id: 6,
            title: "Game Store App Concept",
            category: "design",
            image: links.works6,
            link: "https://dribbble.com",
            text: "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
            video: "https://www.youtube.com/watch?v=IZRduzCkaA0&ab_channel=welcometothebestplace",
            linkText: "good link",
        },
        {
            id: 7,
            title: "Project Managment Illustration",
            category: "art",
            image: links.works3,
            link: "https://pinterest.com",
            text: "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
            video: "https://www.youtube.com/watch?v=IZRduzCkaA0&ab_channel=welcometothebestplace",
            linkText: "good link",
        },
        {
            id: 8,
            title: "Guest App Walkthrough Screens",
            category: "design",
            image: links.works1,
            link: "https://pinterest.com",
            text: "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
            video: "https://www.youtube.com/watch?v=IZRduzCkaA0&ab_channel=welcometothebestplace",
            linkText: "good link",
        },
        {
            id: 9,
            title: "Delivery App Wireframe",
            category: "branding",
            image: links.works4,
            link: "https://pinterest.com",
            text: "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
            video: "https://www.youtube.com/watch?v=IZRduzCkaA0&ab_channel=welcometothebestplace",
            linkText: "good link",
        },
        {
            id: 10,
            title: "Game Store App Concept",
            category: "design",
            image: links.works6,
            link: "https://dribbble.com",
            text: "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
            video: "https://www.youtube.com/watch?v=IZRduzCkaA0&ab_channel=welcometothebestplace",
            linkText: "good link",
        },
    ],
    filters: [
        {
            id: 1,
            text: "Everything",
        },
        {
            id: 2,
            text: "Projects Founded",
        },
        {
            id: 3,
            text: "Developed for Others",
        },
        {
            id: 4,
            text: "Lecturer",
        },
        {
            id: 5,
            text: "Content I Made",
        },
    ],
    isBlogShown: true,
    blogTitle: 'Latest Posts',
    allBlogs: [
        {
            id: 1,
            title: "How to Build a Company (Startup / Lifestyle / Social Impact)?",
            image: "https://firebasestorage.googleapis.com/v0/b/meemoc-ec86b.appspot.com/o/entrepreneur-1340649_1280.jpg?alt=media&token=c7064e94-e562-4664-9d28-d53e514f0544",
            filesource: "https://sumbli.com/p/OThHJlEZqt/How-to-Build-a-Company-(Startup--Lifestyle--Social-Impact)-",
            date: "09 February, 2020",
            author: "John Smith",
            category: "Tutorials",
            isBlogExternal: 'false',
        },
        {
            id: 2,
            title: "How to Live Well?",
            image: "https://firebasestorage.googleapis.com/v0/b/meemoc-ec86b.appspot.com/o/success-846055_1280%20(1).jpgC6Ypp1591457282476?alt=media&token=096b59e1-fc1f-44cb-9dad-19eb15be8260",
            filesource: "https://sumbli.com/p/ipMvmcaBRx/How-to-Live-Well",
            date: "13 March, 2020",
            author: "John Smith",
            category: "Tutorials",
            isBlogExternal: 'false',
        },
        {
            id: 3,
            title: "Knowledge Resources",
            image: "https://firebasestorage.googleapis.com/v0/b/meemoc-ec86b.appspot.com/o/books-1281581_1280.jpg?alt=media&token=4ea818a1-1629-42b5-8ff7-00b3488ed49d",
            filesource: "https://sumbli.com/p/VYB5pFxon5/Knowledge-Resources",
            date: "27 February, 2021",
            author: "John Smith",
            category: "Links",
            isBlogExternal: 'false',
        },
        {
            id: 4,
            title: "Web Development Guide",
            image: "https://firebasestorage.googleapis.com/v0/b/meemoc-ec86b.appspot.com/o/programming-1873854_1280%20(1).png596eM1591291452262?alt=media&token=b1145668-dfca-4800-a6b7-8a6e644d3b3f",
            filesource: "https://sumbli.com/p/LUwHmSq4zO/Web-Development-Guide-%7C-Full-Stack-Development",
            date: "02 April, 2019",
            author: "John Smith",
            category: "Tutorials",
            isBlogExternal: 'false',
        }
    ],
    isGalleryShown: true,
    galleryTitle: 'Gallery',
    galleryData: [
        {
            id: 1,
            image: links.works1
        },
        {
            id: 2,
            image: links.works2
        },
        {
            id: 3,
            image: links.works3
        },
        {
            id: 4,
            image: links.works4
        },
        {
            id: 5,
            image: links.works5
        },
        {
            id: 6,
            image: links.works6
        },
        {
            id: 7,
            image: links.works1
        },
        {
            id: 8,
            image: links.works1
        },
        {
            id: 9,
            image: links.works2
        },
        {
            id: 10,
            image: links.works3
        },
        {
            id: 11,
            image: links.works4
        },
        {
            id: 12,
            image: links.works5
        },
        {
            id: 13,
            image: links.works6
        },
        {
            id: 14,
            image: links.works1
        },
    ],
    isContactShown: true,
    contactTitle: 'Get in Touch',
    contactText: 'Let\'s talk about everything!',
    myEmail: 'email@gmail.com',
    myPhone: '+1-541-754-3010',
    isPricingShown: true,
    pricingTitle: 'Pricing Plans',
    priceboxData: [
        {
            id: 1,
            title: "Basic",
            icon: links.price1,
            content: [
                {
                    id: 0,
                    text: "A Simple option but powerful to manage your business",
                },
                {
                    id: 2,
                    text: "Email support",
                },
            ],
            price: 18,
            CurrencySymbol: '$',
            recurringPaymentPeriod: 'Month',
            buttonText: 'Get Started',
            buttonLink: 'https://www.paypal.com/donate/?hosted_button_id=EQS56KNJ5YUVW',
            best: false,
            badge: '',
        },
        {
            id: 2,
            title: "Premium",
            icon: links.price2,
            content: [
                {
                    id: 1,
                    text: "Unlimited product including apps integrations and more features",
                },
                {
                    id: 2,
                    text: "Mon-Fri support",
                },
            ],
            price: 49,
            CurrencySymbol: '$',
            recurringPaymentPeriod: 'Month',
            buttonText: 'Get Started',
            buttonLink: 'https://www.paypal.com/donate/?hosted_button_id=EQS56KNJ5YUVW',
            best: true,
            badge: "Recommended",
        },
        {
            id: 3,
            title: "Ultimate",
            icon: links.price3,
            content: [
                { id: 1, text: "A wise option for large companies and individuals" },
                { id: 2, text: "24/7 support" },
            ],
            price: 99,
            CurrencySymbol: '$',
            recurringPaymentPeriod: 'Month',
            buttonText: 'Get Started',
            buttonLink: 'https://www.paypal.com/donate/?hosted_button_id=EQS56KNJ5YUVW',
            best: false,
            badge: '',
        },
    ],
    isTestimonialShown: true,
    testimonialTitle: 'Clients & Review',
    testimonialData: [
        {
            id: 1,
            avatar: "images/avatar-1.svg",
            userName: "John Doe",
            subtitle: "Product designer",
            review:
                "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍",
        },
        {
            id: 2,
            avatar: "images/avatar-2.svg",
            userName: "John Doe",
            subtitle: "Product designer",
            review:
                "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍",
        },
        {
            id: 3,
            avatar: "images/avatar-3.svg",
            userName: "John Doe",
            subtitle: "Product designer",
            review:
                "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍",
        },
    ],
    isBrandLogosShown: true,
    brandlogoData: [
        {
            id: 1,
            image: "images/client-1.svg",
        },
        {
            id: 2,
            image: "images/client-2.svg",
        },
        {
            id: 3,
            image: "images/client-3.svg",
        },
        {
            id: 4,
            image: "images/client-4.svg",
        },
        {
            id: 5,
            image: "images/client-5.svg",
        },
        {
            id: 6,
            image: "images/client-6.svg",
        },
        {
            id: 7,
            image: "images/client-7.svg",
        },
        {
            id: 8,
            image: "images/client-8.svg",
        },
    ],
    isShareShown: true,
    shareTitle: 'Share This Page',
    shareUrl: 'https://onlinepag.es/',
    isLinksShown: true,
    linksTitle: 'Links',
    bigLinks: [
        { id: 1, title: 'GitHub Profile', url: 'https://github.com/', icon: 'icon-social-github' },
        { id: 2, title: 'LinkedIn Profile', url: 'https://www.linkedin.com/in/', icon: 'icon-social-linkedin' },
        { id: 3, title: 'Personal Website', url: 'https://example.com', icon: 'icon-globe' },
        { id: 4, title: 'Email', url: 'name namename@gmail.com', icon: 'icon-envelope' },
        { id: 5, title: 'Instagram Profile', url: 'https://www.instagram.com/', icon: 'icon-social-instagram' },
        { id: 6, title: 'Facebook Profile', url: 'https://www.facebook.com/', icon: 'icon-social-facebook' },
        { id: 7, title: 'Twitter Profile', url: 'https://twitter.com/', icon: 'icon-social-twitter' }
    ],
    isSupportShown: true,
    supportTitle: 'Support',
    isSupportExternal: 'false',
    donationText: 'Support my work by donating any amount you wish!',
    paypalUrl: 'https://www.paypal.com/donate/?hosted_button_id=EQS56KNJ5YUVW',
    donationUrl: 'https://www.paypal.com/donate/?hosted_button_id=EQS56KNJ5YUVW',
    donationUrlText: 'Donate with Paypal',
    isSubscribeShown: true,
    subscribeTitle: 'Subscribe',
    subscribeText: 'Subscribe for not missing my latest updates',
};
