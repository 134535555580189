import React from "react";

function Card({ cardItem }) {
    const { icon, title, content } = cardItem;
    return (
        <div className="price-item bg-white rounded shadow-dark text-center mt-2">
            {icon && <img src={icon} alt="Card Icon" style={{ height: '125px' }} />}
            <h2 className="plan">{title}</h2>
            <p className='drop-line'>{content}</p>
        </div>
    );
}

export default Card;
