import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-scroll';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


export default function AccordionItem({ children, title, handleToggle, value, defaultExpanded, order }) {

    const [expanded, setExpanded] = useState(!defaultExpanded);

    const classes = useStyles();
    return (
        <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
            style={{ marginBottom: 10 }}
        >
            <Grid item xs>

                <Accordion defaultExpanded={defaultExpanded}
                    onChange={(e) => {
                        setExpanded(e.currentTarget.getAttribute('aria-expanded') === 'true');
                    }}>
                    <Link
                        to={expanded ? `section-${order}` : '#'}
                        spy={true}
                        smooth='true'
                        duration={500}
                        style={{ color: 'black' }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>{title}</Typography>
                        </AccordionSummary>
                    </Link>
                    <AccordionDetails>
                        {children}
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Button value={value} onClick={handleToggle} style={{ height: '48px' }}><DeleteIcon style={{color:"#ffffff"}} /></Button>
        </Grid>
    )
}
