import React from "react";
import TrackVisibility from "react-on-screen";
import Pagetitle from "../elements/Pagetitle";
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    FacebookMessengerShareButton,
    WorkplaceShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";
import {Grid} from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";
import Typography from "@material-ui/core/Typography";


function Support({ data }) {
    return (
        <section style={{marginTop: 0, paddingTop: 0}} id="support">
            <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
            >
            <div className="container">
                <Pagetitle title={data.supportTitle} />
                <div className="row">
                    <Grid
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="center"
                    >
                        <div style={{marginBottom: 20}}>
                            {data.donationText}
                        </div>
                        {data.isSupportExternal === 'true' ?
                            <a
                                style={{paddingLeft: 10, paddingRight: 10, marginBottom: 15, maxWidth: 400, width: '100%'}}
                                href={data.donationUrl}
                                target='_blank'
                                className="btn btn-default"
                                spy={'true'}
                                smooth={'true'}
                            >
                                    {data.donationUrlText}
                            </a>
                            :
                            <form action="https://www.paypal.com/donate" method="post" target="_top">
                                <input type="hidden" name="hosted_button_id" value={data.paypalUrl.split('hosted_button_id=')[1]} />
                                <input type="image" src="https://www.paypalobjects.com/en_US/IL/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                <img alt="" border="0" src="https://www.paypal.com/en_IL/i/scr/pixel.gif" width="1" height="1" />
                            </form>
                        }
                    </Grid>
                </div>
                <div className="spacer" data-height="40"></div>
            </div>
            </ScrollAnimation>
        </section>
    );
}

export default Support;
