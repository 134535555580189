import React from "react";
import {AppBar, Grid, Toolbar} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";


function Contact(props) {
    return (
        <div style={{ padding: 50}}>
                <h1>You can send us new section by sending the source code of your ReactJS Component to <a href='welcometothebestplace@gmail.com' >welcometothebestplace@gmail.com</a></h1>

            </div>
    );
}

export default Contact;
