import React, { useState, useEffect } from "react";
import NewPageDialog from '../elements/NewPageDialog';
import Card from "../elements/Card";
import { Link } from "react-scroll";
import ScrollAnimation from "react-animate-on-scroll";



function Landing({ data, manage }) {

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');

    const handleClickOpenDialog = (e) => {
        e.preventDefault();
        setOpenDialog(true);
    };

    const handleCloseDialog = (value) => {
        setOpenDialog(false);
        setSelectedValue(value);
    };

    useEffect(() => {
        if (selectedValue) window.location.href = `/submit?page=${selectedValue}`
    }, [selectedValue])

    const whichButton = () => {
        if (data.landingButtonText) {
            switch (data.landingButtonLink) {
                case '/submit':
                    return <a onClick={handleClickOpenDialog} href='submit' className="btn-lg btn btn-default">{data.landingButtonText}</a>
                case 'url':
                    return <a href={'http://' + data.landingButtonUrl} target="_blank" rel="noopener noreferrer" className="btn-lg btn btn-default">{data.landingButtonText}</a>
                default:
                    return <Link
                        className="btn-lg btn btn-default"
                        activeClass="active"
                        to={`section-${data.landingButtonLink}`}
                        spy={true}
                        smooth={true}
                        duration={1000}
                    >
                        {data.landingButtonText}
                    </Link>
            }
        } else return null
    }

    return (
        <section id="landing" className="pt-5" style={{ overflow: 'hidden' }}>
            <NewPageDialog selectedValue={selectedValue} open={openDialog} onClose={handleCloseDialog} />
            <div className="container mb-3">
                <div className="row mb-4 justify-content-between">
                    <div className={data.landingImage ? "col-md-5" : "center-landing"}>
                        <ScrollAnimation
                            animateIn="fadeInLeft"
                            animateOut="fadeInOut"
                            animateOnce={true}
                        >
                            <div className={data.landingImage ? "text-md-left" : "text-center"}>
                                <h1>{data.landingTitle}</h1>
                                <h4 className='drop-line'>{data.landingSubText}</h4>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-6">
                        <ScrollAnimation
                            animateIn="fadeInRight"
                            animateOut="fadeInOut"
                            animateOnce={true}
                            offset={0}
                        >
                            <div className="text-center text-md-left">
                                {data.landingImage && <img src={data.landingImage} alt={'alt'} style={{ maxHeight: '375px', width: '100%' }} />}
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>

                <div className="container">
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                    >
                        {data.landingButtonText && <div className="d-flex justify-content-center m-3">
                            {whichButton()}
                        </div>}
            
                        {data.landingSecondButtonText && <div className="d-flex justify-content-center m-3">
                            <a href={data.landingSecondgButtonLink}>{data.landingSecondButtonText}</a>
                        </div>}
                        <div className="row align-items-center">
                            {data.cards.map((card, i) => {
                                return card && <div key={card.id} className="col-md-4 mx-auto">
                                    <Card cardItem={card} />
                                </div>
                            }
                            )}
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
            {!manage && data.sectionOrder[0] === 'landing' && <div className="row justify-content-center">
                <Link
                    activeClass="active"
                    to={`section-${data.sectionOrder[1]}`}
                    spy={true}
                    smooth={true}
                    duration={1000}
                >
                    <i className="fas fa-chevron-down fa-3x" role='button'></i>
                </Link>
            </div>}

        </section>
    );
}

export default Landing;


