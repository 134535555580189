import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex, changeOrder) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const reorderedPage = result.map((item) => {
        return item.content.props.order
    });
    changeOrder(reorderedPage);
    return result;
};

function Item({ item, index }) {
    return (
        <Draggable draggableId={item.id} index={index}>
            {provided => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {item.content}
                </div>
            )}
        </Draggable>
    );
}

const ItemList = React.memo(function ItemList({ items, data }) {
    return items.map((item, index) => (
        data[item.content.props.value] ? <Item item={item} index={index} key={item.id + item.content.props.order} /> : null
    ));
});

export default function Reorder({ children, data, changeOrder }) {

    const { sectionOrder } = data;
    const [orderedList, setOrderedList] = useState(children);
    const initial = Array.from({ length: orderedList.length }, (value, index) => index).map(index => {
        const custom = {
            id: `id-${index}`,
            content: orderedList[index]
        };

        return custom;
    })

    useEffect(() => {
        const reorder = sectionOrder.map((sectionName) => {
            let section = '';
            children.forEach(child => {
                section = child.props.order === sectionName ? child : section;
            });
            return section
        })
        setOrderedList(reorder)
    }, [sectionOrder, data, children])

    useEffect(() => {
        const list = Array.from({ length: orderedList.length }, (value, index) => index).map(index => {
            const custom = {
                id: `id-${index}`,
                content: orderedList[index]
            };

            return custom;
        });
        setState({ items: list })
    }, [orderedList, data])


    const [state, setState] = useState({ items: initial });

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            state.items,
            result.source.index,
            result.destination.index,
            changeOrder
        );

        setState({ items });
    }

    return (
        <DragDropContext onDragEnd={onDragEnd} >
            <Droppable droppableId="list" >
                {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps} >
                        <ItemList items={state.items} data={data} />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}