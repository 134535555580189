import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import DvrIcon from '@material-ui/icons/Dvr';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { blue } from '@material-ui/core/colors';
import DescriptionIcon from '@material-ui/icons/Description';

const pages = [ 'Personal','Landing Page', 'Blog', 'All Sections', 'Blank'];
const icons = [
    <PersonIcon />,
    <AttachMoneyIcon />,
    <DescriptionIcon />,
    <DvrIcon />,
    <DesktopWindowsIcon />
]
const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

export default function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog disableScrollLock onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Choose Page Type</DialogTitle>
            <List>
                {pages.map((page, i) => (
                    <ListItem button onClick={() => handleListItemClick(page)} key={page}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                {icons[i]}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={page} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}
