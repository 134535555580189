import React from "react";

function Pricebox({ priceboxItem }) {
  const { buttonLink, buttonText, icon, CurrencySymbol, recurringPaymentPeriod, title, content, price, badge, best } = priceboxItem;
  return (
    <div
      className={
        best
          ? "price-item bg-white rounded shadow-dark text-center mt-2 best"
          : "price-item bg-white rounded shadow-dark text-center mt-2"
      }
    >
      {badge && <span className="badge">{badge}</span>}
      {icon && <img src={icon} alt="Regular" style={{ height: '150px' }} />}
      <h2 className="plan">{title}</h2>
      {content.map((singleContent) => (
        <p key={singleContent.id}>{singleContent.text}</p>
      ))}
      {price && <h3 className="price">
        <em>{CurrencySymbol}</em>
        {price}
        <span>{recurringPaymentPeriod}</span>
      </h3>}
      {buttonText && <a href={buttonLink} target='_blank' className="btn btn-default">
        {buttonText}
      </a>}
    </div>
  );
}

export default Pricebox;
