import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Pricebox from "../elements/Pricebox";


function Pricing({ data }) {
  return (
    <section id="prices">
      <div className="container">
        <Pagetitle title={data.pricingTitle} />
        <div className="row d-flex justify-content-around align-items-center">
          {data.priceboxData.map((pricebox) => (
            <div key={pricebox.id} className="col-md-4">
              <Pricebox priceboxItem={pricebox} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Pricing;
