import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import {DialogContent, Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";

const sections = ['Landing', 'Header', 'About', 'Services', 'Experience', 'Works', 'Pricing', 'Reviews', 'Posts', 'Gallery', 'Contact', 'Share'];
const indexes = ['isLandingShown', 'isHeaderShown', 'isAboutShown', 'isServicesShown', 'isExperienceShown', 'isPortfolioShown', 'isPricingShown', 'isTestimonialShown', 'isBlogShown', 'isGalleryShown', 'isContactShown', 'isShareShown'];
const icons = ['icon-plane', 'icon-home', 'icon-user-following', 'icon-briefcase', 'icon-graduation', 'icon-layers', 'icon-wallet', 'icon-speech', 'icon-note', 'icon-picture', 'icon-bubbles', 'icon-share']

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

function SimpleDialog({ onClose, open, toggleSection, data }) {
    const classes = useStyles();
    let availableSections = [];
    sections.forEach((section, i) => {
        if(data && !data[indexes[i]]){
            availableSections.push(section);
        }
    });

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (section) => {
        toggleSection(section)
        onClose();
    };


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true}>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >

            <DialogTitle id="simple-dialog-titlדe">Add a section</DialogTitle><Link target='_blank' style={{paddingRight: 15}} to={'/addSection'}>Create new Section</Link>
            </Grid>
            {availableSections.length === 0 && <DialogContent>
                <Typography >No more sections to add</Typography>
            </DialogContent>
            }
            <List>
                {sections.map((section, i) => (
                    data && !data[indexes[i]] &&
                    <ListItem button onClick={() => handleListItemClick(indexes[i])} key={section}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <i className={icons[i]} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={section} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}


export default SimpleDialog;