import React from "react";
import { Link } from "react-router-dom";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";


function Blogs(props) {
  return (
    <section id="blog">
      <div className="container">
        <Pagetitle title={props.data.blogTitle} />
        <div className="row blog-wrapper">
          {props.data.allBlogs.map((blogItem, index) => (
            <React.Fragment key={blogItem.id}>
              {(index <= 2) &&
                <div className="col-md-4" >
                  <Blog data={props.data} blogData={blogItem} />
                </div>
              }
            </React.Fragment>
          ))}
        </div>
        <div className="text-center">
          <div className="spacer" data-height="30"></div>
          <Link to={window.location.pathname + window.location.search + "/posts"} className="btn btn-default">
            Show all posts
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Blogs;
