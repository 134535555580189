import React from "react";
import TrackVisibility from "react-on-screen";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";


function About({ data }) {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title={data.aboutTitle} />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              {data.profileImageAbout && <img src={data.profileImageAbout} alt='Profile' />}
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p className='drop-line'>{data.intro}</p>
                  <div className="mt-3">
                    {data.downloadCV && <a href={data.cvLink} target="_blank" rel="noopener noreferrer" className="btn btn-default">
                      {data.downloadCV}
                    </a>}
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {data.bars.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
      </div>
    </section>
  );
}

export default About;
