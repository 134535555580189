import React, { useEffect, useState } from "react";
import Header from "../components/layouts/Header";
import Page from "./Page";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import FileSaver from 'file-saver';
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Snackbar,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio, ListItemText, ListItemIcon,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Cancel as CancelIcon,
  Block as BlockIcon,
  Edit,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import firebase from "firebase";
import { Redirect } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import SimpleDialog from "../components/elements/AddSectuibDialog";
import Reorder from "../components/elements/Reorder";
import { defaultData } from "../defaultData";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import AccordionItem from "../components/elements/AccordionItem";
import TopBar from "../components/elements/TopBar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const icons = [
  "icon-social-github",
  "icon-social-linkedin",
  "icon-globe",
  "icon-envelope",
  "icon-social-instagram",
  "icon-social-facebook",
  "icon-social-twitter",
];
let tempNewData;

function Editor({ user, isEditor }) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [isNoPermissions, setIsNoPermissions] = useState(false);
  const [urlAvailability, setUrlAvailability] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [saved, setSaved] = useState("");
  const [validURL, setValidURL] = useState(true);
  const [url, setUrl] = useState("");
  const [changedImages, setChangedImages] = useState([])
  const [shareUrl, setShareUrl] = useState(
    data && data.shareUrl ? data.shareUrl : "https://onlinepag.es/"
  );
  const { register, handleSubmit, errors } = useForm();
  const firestore = firebase.firestore();
  const storage = firebase.storage();
  const pagesRef = firestore.collection("pages");
  let { id } = useParams();
  let query = pagesRef.where(
    "id",
    "==",
    id ? id : process.env.REACT_APP_FIREBASE_PAGE_ID
  );
  const [pages] = useCollectionData(query);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
      margin: theme.spacing(0),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    textField: {
      "& .MuiFormHelperText-root": {
        color:
          urlAvailability === 0
            ? ""
            : urlAvailability === 1 || !validURL
              ? "red"
              : "green",
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (
      data &&
      data.shareUrl &&
      data.shareUrl !== shareUrl &&
      data.shareUrl !== "https://onlinepag.es/"
    ) {
      setShareUrl(data.shareUrl);
      handleChange({ id: '1', target: { value: data.shareUrl } },
        'shareUrl'
      )
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      delayedHandleChange(
        {
          target: {
            value: shareUrl,
          },
        },
        ["shareUrl"]
      );
    }
  }, [shareUrl]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  };

  const handleSnackOpen = (value, color, url) => {
    setSaved([value, color, url]);
    setOpenSnack(true);
  };

  const handleSnackClose = () => {
    setOpenSnack(false);
  };

  useEffect(() => {
    if (pages && pages[0]) {
      setData(pages[0]);
      setUrlAvailability(2);
      setIsLoading(false);
      if (
        (user && pages[0].userId !== user.uid && isEditor) ||
        (!user && isEditor)
      ) {
        setIsNoPermissions(true);
      }
    } else if (pages) {
      setIsLoading(false);
      if (isEditor) {
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page');
        let tempData = { ...defaultData }

        switch (page) {
          case 'Blank':
            tempData.isHeaderShown = false;
            tempData.isLandingShown = false;
            tempData.isLinksShown = false;
            tempData.isAboutShown = false;
            tempData.isServicesShown = false;
            tempData.isExperienceShown = false;
            tempData.isPortfolioShown = false;
            tempData.isPricingShown = false;
            tempData.isTestimonialShown = false;
            tempData.isBrandLogosShown = false;
            tempData.isBlogShown = false;
            tempData.isGalleryShown = false;
            tempData.isSupportShown = false;
            tempData.isContactShown = false;
            tempData.isSubscribeShown = false;
            tempData.isShareShown = false;
            break;
          case 'Personal':
            tempData.isLandingShown = false;
            tempData.isPricingShown = false;
            tempData.isTestimonialShown = false;
            tempData.isBrandLogosShown = false;
            break;
          case 'Landing Page':
            tempData.isHeaderShown = false;
            tempData.isLinksShown = false;
            tempData.isAboutShown = false;
            tempData.isServicesShown = false;
            tempData.isExperienceShown = false;
            tempData.isPortfolioShown = false;
            tempData.isBlogShown = false;
            tempData.isSupportShown = false;
            tempData.isShareShown = false;
            break;
          case 'Blog':
            tempData.isHeaderShown = false;
            tempData.isLandingShown = false;
            tempData.isLinksShown = false;
            tempData.isAboutShown = false;
            tempData.isServicesShown = false;
            tempData.isExperienceShown = false;
            tempData.isPortfolioShown = false;
            tempData.isPricingShown = false;
            tempData.isTestimonialShown = false;
            tempData.isBrandLogosShown = false;
            break;
        }
        setData(tempData);
      }
    }
  }, [pages, user, isEditor]);

  function makeid(length) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    result += new Date().getTime();
    return result;
  }

  function getRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  const saveToFirestore = (data, loggedUser) => {
    const promises = [];
    changedImages.forEach((image) => {
      const { file, key } = image;
      const res = uploadImage(file, data.id, key, loggedUser);
      promises.push(res);
      res.then((res) => {
        updateImage(key, res);
        data = updateImagesForObjects(key, res, data);
      })
    })
    Promise.all(promises).then(() => {
      pagesRef
        .where("pageId", "==", data.pageId)
        .get()
        .then((results) => {
          if (results.docs && results.docs.length > 0) {
            results.docs[0].ref
              .update(data)
              .then((response) => {
                handleSnackOpen(
                  "Page updated successfully!",
                  "success",
                  "/" + data.id
                );
                setUpdating(false);
              })
              .catch(() => {
                handleSnackOpen(
                  "Something went wrong! page failed to update.",
                  "error",
                  null
                );
                setUpdating(false);
              });
          } else {
            pagesRef.add(data).then((response) => {
              handleSnackOpen(
                "Page successfully saved!",
                "success",
                "/" + data.id
              );
              setUpdating(false);
              setData(data);
            });
          }
        });
    });
  }

  const signInWithGoogle = (newData) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((user) => {
        setOpenLoginDialog(false);
        continueSaving(newData, user);
      });
  };

  const continueSaving = (newData, loggedUser) => {
    let currentUser = loggedUser ? loggedUser.user : user;
    if (urlAvailability === 1)
      return handleSnackOpen(
        "URL taken, change it before updating the page.",
        "error"
      );
    let updatedData = { ...data };
    if (!(updatedData.pageId && updatedData.pageId.length > 0)) {
      updatedData.pageId = makeid(10);
    }

    //General Details
    updatedData.userId = currentUser.uid;
    updatedData.id = newData["url"];

    setData(updatedData);
    saveToFirestore(updatedData, currentUser);
  };

  const onSubmit = (newData) => {
    setUpdating(true);
    if (!user) {
      tempNewData = newData;
      setOpenLoginDialog(true);
    } else {
      continueSaving(newData);
    }
  };

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
    setToggleEditor(false);
  };

  function isValidUrl(src) {
    let restricted =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~:?#[]@!$&()*+,;=";
    let isValid = true;
    for (var i = 0; i < src.length; i++) {
      if (!restricted.includes(src.charAt(i))) {
        isValid = false;
      }
    }
    return isValid;
  }

  const handleUrlChange = async (e, value) => {
    setUrl(value);
    let eventData = { id: e.id, target: e.target };
    if (shareUrl.includes("https://onlinepag.es/")) {
      setShareUrl("https://onlinepag.es/" + value);
      handleChange({ id: '1', target: { value: "https://onlinepag.es/" + value } },
        'shareUrl'
      )
    }
    if (isValidUrl(value)) {
      setValidURL(true);
      testUrl(value, eventData);
    } else {
      setValidURL(false);
    }
  };

  const delayedTestUrl = (value, e) => {
    setTimeout((value, e) => {
      testUrl(value, e)
    }, 500);
  }
  // const delayedTestUrl = setTimeout((value, e) => testUrl(value, e), 1);

  const testUrl = (value, e) => {
    //loading
    setUrlAvailability(0);
    if (!value) return;
    let query = pagesRef.where("id", "==", value);
    query.get().then((results) => {
      if (results && results.docs[0] && results.docs[0].data().id) {
        if (results.docs[0].data().pageId === data.pageId) {
          //available
          setUrlAvailability(2);
        } else {
          //taken
          setUrlAvailability(1);
        }
      } else {
        //available
        setUrlAvailability(2);
      }
    });
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  const toggleSection = (e) => {
    let value = e.currentTarget ? e.currentTarget.value : e;
    setData((prevState) => {
      let obj = { ...prevState };
      obj[value] = !obj[value];
      return obj;
    });
  };

  const changeOrder = (newOrder) => {
    setData((prevState) => {
      let obj = { ...prevState };
      obj.sectionOrder = newOrder;
      return obj;
    });
  };


  const uploadImage = (file, url, name, loggedUser) => {
    let storageRef = storage.ref();
    let logoRef = storageRef.child(`${loggedUser.uid}/${url}/images/${name}`);
    if (!file) {
      return;
    }
    if (file.type.split("/")[0] === "image") {
      return logoRef.put(file).then(() => {
        return logoRef.getDownloadURL().then((url) => {
          return url;
        });
      });
    } else {
      return;
    }
  };

  const removeInput = (key, index) => {
    setData((prevData) => {
      let obj = { ...prevData };
      obj[key].splice(index, 1);
      return obj;
    });
  };

  const addInput = (key, limit) => {
    setData((prevData) => {
      let obj = { ...prevData };
      if (obj[key].length === limit) {
        handleSnackOpen("You have reached the maximum limit.", "warning");
        return obj;
      }
      if (key === "priceboxData") {
        if (obj[key].length !== 0) {
          obj[key].push({
            id: obj[key][obj[key].length - 1].id + 1,
            content: [{ id: 1 }, { id: 2 }],
          });
        } else {
          obj[key].push({ id: 1, content: [{ id: 1 }, { id: 2 }] });
        }
      } else {
        if (key !== "titles") {
          if (obj[key].length !== 0) {
            obj[key].push({ id: obj[key][obj[key].length - 1].id + 1 });
          } else {
            obj[key].push({ id: 1 });
          }
        } else {
          if (obj[key].length !== 0) {
            obj[key].push({ id: obj[key][obj[key].length - 1].id + 1, text: '' });
          } else {
            obj[key].push({ id: 1, text: '' });
          }
        }
      }
      return obj;
    });
  };


  const updateData = (eventData, key) => {
    setData((prevData) => {
      let obj = { ...prevData };
      switch (key.length) {
        case 1:
          obj[key[0]] = eventData.target.value;
          break;
        case 2:
          obj[key[0]][key[1]] = eventData.target.value;
          break;
        case 3:
          obj[key[0]][key[1]][key[2]] = eventData.target.value;
          break;
        case 5:
          obj[key[0]][key[1]][key[2]][key[3]][key[4]] = eventData.target.value;
          break;
      }
      return obj;
    });
  };

  const removeImage = (key) => {
    setData((prevData) => {
      let obj = { ...prevData };
      switch (key.length) {
        case 1:
          obj[key[0]] = "";
          break;
        case 3:
          obj[key[0]][key[1]][key[2]] = "";
          break;
      }
      return obj;
    });
  };


  const updateImagesForObjects = (key, value, obj) => {
    switch (key.length) {
      case 1:
        obj[key[0]] = value;
        break;
      case 3:
        obj[key[0]][key[1]][key[2]] = value;
        break;
    }
    return obj;
  };

  const updateImage = (key, value) => {
    setData((prevData) => {
      let obj = { ...prevData };
      switch (key.length) {
        case 1:
          obj[key[0]] = value;
          break;
        case 3:
          obj[key[0]][key[1]][key[2]] = value;
          break;
      }
      return obj;
    });
  };

  const handleImageChange = (e, key) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      updateImage(key, e.target.result);
    };
    reader.onabort = () => {
      console.log("abort");
    };
    if (e.currentTarget.files[0]) {
      reader.readAsDataURL(e.currentTarget.files[0]);
      setChangedImages(prevState => [...prevState, { file: e.currentTarget.files[0], key }])
    }
  };

  const delayedHandleChange = debounce(
    (eventData, key) => updateData(eventData, key),
    500
  );

  const handleChange = (e, key) => {
    let eventData = { id: e.id, target: e.target };
    delayedHandleChange(eventData, key);
  };

  const [mainClasses, setMainClasses] = useState("content");
  useEffect(() => {
    if (toggleMenu && isEditor) {
      setMainClasses("content open flex-main");
    } else if (toggleMenu && !isEditor) {
      setMainClasses("content open marginLeft");
    } else if (!toggleMenu && isEditor) {
      setMainClasses("flex-main");
    } else {
      setMainClasses("content marginLeft");
    }
  }, [toggleMenu, isEditor]);

  const [toggleEditor, setToggleEditor] = useState(true);

  const editorToggle = () => {
    setToggleEditor((prevState) => !prevState);
  };


  function downloadHTML(e) {
    e.preventDefault();
    const htmlText = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${data.pageTitle}</title>
<link runat="server" rel="shortcut icon" href="${data.favicon}" type="image/x-icon"/>
 <style type="text/css">
        body, html
        {
            margin: 0; padding: 0; height: 100%; overflow: hidden;
        }

        #content
        {
            position:absolute; left: 0; right: 0; bottom: 0; top: 0px; 
        }
    </style>
</head>
<body>
    <div id="content">
        <iframe width="100%" height="100%" frameborder="0" src="https://onlinepag.es/${data.id}" />
    </div>
</body>
</html>
`;
    let file = new File([htmlText], "index.html", {
      type: "text/plain;charset=utf-8",
    });
    FileSaver.saveAs(file);
  }

  return (
    <>
      <SimpleDialog
        open={openDialog}
        onClose={handleDialogClose}
        toggleSection={toggleSection}
        data={data}
      />
      <Dialog
        onClose={handleCloseLoginDialog}
        aria-labelledby="customized-dialog-title"
        open={openLoginDialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseLoginDialog}
        >
          Sign In to Publish Your Page
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="center" alignItems="center">
            <Link
              to='#'
              onClick={() => signInWithGoogle(tempNewData)}
              className="btn btn-default mt-1"
            >
              Sign In / Up
            </Link>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLoginDialog} color="primary">
            cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity={saved[1]}>
          {saved[0]}
          {saved[2] && (
            <Link
              to={saved[2]}
              style={{
                color: "#ffffff",
                textDecoration: "underline",
                marginLeft: 10,
              }}
              target="_blank"
            >
              VIEW LIVE VERSION
            </Link>
          )}
        </Alert>
      </Snackbar>
      {isNoPermissions ? (
        <>
          {user ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
              }}
            >
              <div
                className="rounded data-background padding-30 text-center shadow-blue"
                data-color="#6C6CE5"
              >
                <BlockIcon style={{ height: "80", width: "80" }} />
                <h3 className="mb-3 mt-2 text-center">
                  It appears you do not have <br /> permission to edit this page
                </h3>
                <a href={`/${data.id}`} className="btn btn-default mt-1">
                  View Page
                </a>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
              }}
            >
              <div
                className="rounded data-background padding-30 text-center shadow-blue"
                data-color="#6C6CE5"
              >
                <img
                  src={defaultData.profileImage}
                  alt={"profile img"}
                  width="108px"
                />
                <h3 className="mb-3 mt-2 text-center">
                  It appears you're not logged in
                </h3>
                <Link to="/admin" className="btn btn-default mt-1">
                  Sign In / Up
                </Link>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {data ? (
            <>
              {
                <Header
                  data={data}
                  toggleMenu={toggleMenu}
                  headerToggler={headerToggler}
                  toggleEditor={toggleEditor}
                  editorToggle={editorToggle}
                  isEditor={isEditor}
                />
              }
              <main className={mainClasses}>
                <Page
                  data={data}
                  toggleEditor={toggleEditor}
                  isEditor={isEditor}
                />
                {isEditor && (
                  <>
                    <i className='icon-pencil editor-button' onClick={editorToggle} />
                    <div
                      className={toggleEditor ? "editor-open" : "editor-close"}
                    >
                      {/*<h1 className={!toggleEditor ? 'move-editor' : 'move-editor-title'} style={{ backgroundColor: '#3f51b5', position: 'fixed', minWidth: '500px', paddingLeft: '20px' }}>Page Editor</h1>*/}
                      <div
                        style={{ backgroundColor: "#353353", margin: 0, border: '2px solid #ffffff' }}
                        className={`fixed-editor ${!toggleEditor && "move-editor"
                          }`}
                      >
                        <TopBar editor user={user}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <h2 style={{ color: "#ffffff" }}>
                              Editor
                            </h2>
                            <h3 style={{ color: "#ffffff" }}>
                              General Details
                            </h3>
                            <Accordion defaultExpanded>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                              >
                                <Typography className={classes.heading}>
                                  General Details
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="form-group">
                                  <TextField
                                    autoFocus
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          onlinepag.es/
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={!validURL}
                                    label="Page URL"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    size="small"
                                    defaultValue={data.id}
                                    required
                                    className={classes.textField}
                                    onChange={(e) => {
                                      handleUrlChange(e, e.target.value);
                                    }}
                                    helperText={
                                      !validURL
                                        ? "Invalid URL"
                                        : urlAvailability === 0
                                          ? "Checking availability..."
                                          : urlAvailability === 1
                                            ? "URL taken"
                                            : urlAvailability === 2
                                              ? "URL available"
                                              : ""
                                    }
                                    name="url"
                                    inputRef={register()}
                                  />
                                  <TextField
                                    label="Page title"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    size="small"
                                    defaultValue={data.pageTitle}
                                    onChange={(e) => {
                                      handleChange(e, ["pageTitle"]);
                                    }}
                                  />
                                  <label>
                                    Page Icon{" "}
                                    <a
                                      style={{ color: "#1a0dab" }}
                                      href="https://icoconvert.com/"
                                      target="_blank"
                                    >
                                      (ICO File)
                                    </a>
                                  </label>
                                  <br />
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    component="label"
                                  >
                                    {data.favicon ? (
                                      <img
                                        src={data.favicon}
                                        alt="Landing Image"
                                        style={{ height: "40px" }}
                                      />
                                    ) : (
                                      <span>Upload Image</span>
                                    )}
                                    <input
                                      type="file"
                                      accept="image/x-icon"
                                      hidden
                                      onChange={(e) => {
                                        handleImageChange(e, ["favicon"]);
                                      }}
                                    />
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      removeImage(["favicon"]);
                                    }}
                                  >
                                    <CancelIcon />
                                  </Button>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <div className={classes.root}>
                              <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="flex-start"
                              >
                                <h3 style={{ color: "#ffffff" }}>Sections</h3>
                                <Link
                                  to="#"
                                  className="btn btn-default"
                                  onClick={handleDialogOpen}
                                  spy={"true"}
                                  smooth={"true"}
                                  duration={500}
                                >
                                  <AddIcon />
                                </Link>
                              </Grid>
                              <Typography
                                style={{ marginLeft: 10, color: "#ffffff" }}
                                variant="caption"
                              >
                                *Drag to reorder the sections
                              </Typography>
                              <Reorder data={data} changeOrder={changeOrder}>
                                <AccordionItem
                                  title="Landing"
                                  handleToggle={toggleSection}
                                  order="landing"
                                  value="isLandingShown"
                                >
                                  <div className="form-group">
                                    <h4>Landing</h4>
                                    <TextField
                                      label="Landing title"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.landingTitle}
                                      onChange={(e) => {
                                        handleChange(e, ["landingTitle"]);
                                      }}
                                    />
                                    <TextField
                                      label="Short description"
                                      type="text"
                                      multiline
                                      rows={4}
                                      variant="outlined"
                                      fullWidth
                                      defaultValue={data.landingSubText}
                                      margin="normal"
                                      size="small"
                                      onChange={(e) => {
                                        handleChange(e, ["landingSubText"]);
                                      }}
                                    />
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      component="label"
                                    >
                                      {data.landingImage ? (
                                        <img
                                          src={data.landingImage}
                                          alt="Landing Image"
                                          style={{ height: "40px" }}
                                        />
                                      ) : (
                                        <span>Upload Image</span>
                                      )}
                                      <input
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        onChange={(e) => {
                                          handleImageChange(e, [
                                            "landingImage",
                                          ]);
                                        }}
                                      />
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        removeImage(["landingImage"]);
                                      }}
                                    >
                                      <CancelIcon />
                                    </Button>
                                    <h4>Button</h4>
                                    <TextField
                                      label="Button text"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      defaultValue={data.landingButtonText}
                                      size="small"
                                      onChange={(e) => {
                                        handleChange(e, ["landingButtonText"]);
                                      }}
                                    />
                                   
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formControl + " mt-2"}
                                    >
                                      <InputLabel id="button-link-label">
                                        Button Link
                                      </InputLabel>
                                      <Select
                                        labelId="button-link-label"
                                        id="button-link"
                                        label="Button Link"
                                        value={
                                          data.landingButtonLink === "/submit"
                                            ? ""
                                            : data.landingButtonLink
                                        }
                                        onChange={(e) => {
                                          updateData(e, ["landingButtonLink"]);
                                        }}
                                      >
                                        <MenuItem value="landing">
                                          <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={"home"}>Home</MenuItem>
                                        <MenuItem value={"landing"}>
                                          Landing
                                        </MenuItem>
                                        <MenuItem value="landing">
                                          <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={"home"}>Home</MenuItem>
                                        <MenuItem value={"about"}>
                                          About
                                        </MenuItem>
                                        <MenuItem value={"services"}>
                                          Services
                                        </MenuItem>
                                        <MenuItem value={"experiences"}>
                                          Experience
                                        </MenuItem>
                                        <MenuItem value={"works"}>
                                          Works
                                        </MenuItem>
                                        <MenuItem value={"pricing"}>
                                          Pricing
                                        </MenuItem>
                                        <MenuItem value={"reviews"}>
                                          Reviews
                                        </MenuItem>
                                        <MenuItem value={"brandlogos"}>
                                          Brand Logos
                                        </MenuItem>
                                        <MenuItem value={"posts"}>
                                          Posts
                                        </MenuItem>
                                        <MenuItem value={"gallery"}>
                                          Gallery
                                        </MenuItem>
                                        <MenuItem value={"contact"}>
                                          Contact
                                        </MenuItem>
                                        <MenuItem value={"support"}>
                                          Support
                                        </MenuItem>
                                        <MenuItem value={"subscribe"}>
                                          Subscribe
                                        </MenuItem>
                                        <MenuItem value={"links"}>
                                          Links
                                        </MenuItem>
                                        <MenuItem value={"share"}>
                                          Share
                                        </MenuItem>
                                        <MenuItem value={"url"}>
                                          Url
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                    <TextField
                                      label="Secondary Button text"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      defaultValue={data.landingSecondButtonText}
                                      size="small"
                                      onChange={(e) => {
                                        handleChange(e, ["landingSecondButtonText"]);
                                      }}
                                    />
                                    <TextField
                                      label="Secondary Button Link"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      defaultValue={data.landingSecondgButtonLink}
                                      size="small"
                                      onChange={(e) => {
                                        handleChange(e, ["landingSecondgButtonLink"]);
                                      }}
                                    />
                                    {data.landingButtonLink === 'url' && <TextField
                                      label="URL"
                                      type="text"
                                      variant="outlined"
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            www.
                                          </InputAdornment>
                                        ),
                                      }}
                                      fullWidth
                                      margin="normal"
                                      defaultValue={data.landingButtonUrl}
                                      size="small"
                                      onChange={(e) => {
                                        handleChange(e, ["landingButtonUrl"]);
                                      }}
                                    />}
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                    >
                                      <h4>Cards</h4>
                                      <Button
                                        onClick={() => {
                                          addInput("cards", 999);
                                        }}
                                        className="mt-auto mb-auto"
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    {data &&
                                      data.cards.map((card, i) => {
                                        return (
                                          <Grid key={card.id + i}>
                                            <TextField
                                              label="Card title"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              defaultValue={card && card.title}
                                              size="small"
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "cards",
                                                  i,
                                                  "title",
                                                ]);
                                              }}
                                            />
                                            <TextField
                                              label="Card description"
                                              type="text"
                                              multiline
                                              rows={3}
                                              variant="outlined"
                                              defaultValue={
                                                card && card.content
                                              }
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "cards",
                                                  i,
                                                  "content",
                                                ]);
                                              }}
                                            />
                                            <Grid
                                              container
                                              direction="row"
                                              justify="space-between"
                                              alignItems="flex-start"
                                            >
                                              <div>
                                                <Button
                                                  color="primary"
                                                  variant="outlined"
                                                  component="label"
                                                >
                                                  {card.icon ? (
                                                    <img
                                                      src={card.icon}
                                                      alt="Card Image"
                                                      style={{ height: "40px" }}
                                                    />
                                                  ) : (
                                                    <span>Upload Image</span>
                                                  )}
                                                  <input
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(e) => {
                                                      handleImageChange(e, [
                                                        "cards",
                                                        i,
                                                        "icon",
                                                      ]);
                                                    }}
                                                  />
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    removeImage([
                                                      "cards",
                                                      i,
                                                      "icon",
                                                    ]);
                                                  }}
                                                >
                                                  <CancelIcon />
                                                </Button>
                                              </div>
                                              <Button
                                                onClick={() => {
                                                  removeInput("cards", i);
                                                }}
                                              >
                                                <DeleteIcon />
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Header"
                                  handleToggle={toggleSection}
                                  order="home"
                                  value="isHeaderShown"
                                >
                                  <div className="form-group">
                                    <h4>Personal Info</h4>
                                    <TextField
                                      label="First Name"
                                      defaultValue={data.firstName}
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      onChange={(e) => {
                                        handleChange(e, ["firstName"]);
                                      }}
                                    />
                                    <TextField
                                      label="Last Name"
                                      defaultValue={data.lastName}
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      onChange={(e) => {
                                        handleChange(e, ["lastName"]);
                                      }}
                                    />
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      component="label"
                                    >
                                      {data.profileImage ? (
                                        <img
                                          src={data.profileImage}
                                          alt="Profile Image"
                                          style={{ height: "40px" }}
                                        />
                                      ) : (
                                        <span>Upload Image</span>
                                      )}
                                      <input
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        onChange={(e) => {
                                          handleImageChange(e, [
                                            "profileImage",
                                          ]);
                                        }}
                                      />
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        removeImage(["profileImage"]);
                                      }}
                                    >
                                      <CancelIcon />
                                    </Button>
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                    >
                                      <h4>Titles</h4>
                                      <Button
                                        className="mt-auto mb-auto"
                                        onClick={() => {
                                          addInput("titles", 999);
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    {data.titles.map((title, i) => {
                                      return (
                                        <Grid
                                          key={title.id + i}
                                          container
                                          direction="row"
                                          justify="space-between"
                                          alignItems="flex-start"
                                        >
                                          <TextField
                                            defaultValue={title.text}
                                            label="Title"
                                            type="text"
                                            variant="outlined"
                                            margin="normal"
                                            size="small"
                                            onChange={(e) => {
                                              handleChange(e, ["titles", i, 'text']);
                                            }}
                                          />
                                          <Button
                                            onClick={() => {
                                              removeInput("titles", i);
                                            }}
                                            className="mt-auto mb-auto"
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </Grid>
                                      );
                                    })}
                                    <h4>Links</h4>
                                    {data.links.map((link, i) => {
                                      return (
                                        <Grid
                                          container
                                          direction="row"
                                          alignItems="flex-start"
                                          key={i}
                                        >
                                          <i
                                            className={`${icons[i]} mt-auto mb-auto mr-2 fa-lg`}
                                            style={{ width: "20px" }}
                                          ></i>
                                          <TextField
                                            defaultValue={link.url}
                                            label={link.platform}
                                            type="text"
                                            variant="outlined"
                                            margin="normal"
                                            size="small"
                                            onChange={(e) => {
                                              handleChange(e, [
                                                "links",
                                                i,
                                                "url",
                                              ]);
                                            }}
                                          />
                                        </Grid>
                                      );
                                    })}
                                    <h4>Button</h4>
                                    <TextField
                                      label="Button text"
                                      defaultValue={data.headerButtonText}
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      onChange={(e) => {
                                        handleChange(e, ["headerButtonText"]);
                                      }}
                                    />
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formControl + " mt-2"}
                                    >
                                      <InputLabel id="button-link-label">
                                        Button Link
                                      </InputLabel>
                                      <Select
                                        labelId="button-link-label"
                                        id="button-link"
                                        label="Button Link"
                                        value={
                                          data.headerButtonLink === "/submit"
                                            ? ""
                                            : data.headerButtonLink
                                        }
                                        onChange={(e) => {
                                          updateData(e, ["headerButtonLink"]);
                                        }}
                                      >
                                        <MenuItem value="home">
                                          <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={"landing"}>
                                          Landing
                                        </MenuItem>
                                        <MenuItem value="landing">
                                          <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={"home"}>Home</MenuItem>
                                        <MenuItem value={"about"}>
                                          About
                                        </MenuItem>
                                        <MenuItem value={"services"}>
                                          Services
                                        </MenuItem>
                                        <MenuItem value={"experiences"}>
                                          Experience
                                        </MenuItem>
                                        <MenuItem value={"works"}>
                                          Works
                                        </MenuItem>
                                        <MenuItem value={"pricing"}>
                                          Pricing
                                        </MenuItem>
                                        <MenuItem value={"reviews"}>
                                          Reviews
                                        </MenuItem>
                                        <MenuItem value={"brandlogos"}>
                                          Brand Logos
                                        </MenuItem>
                                        <MenuItem value={"posts"}>
                                          Posts
                                        </MenuItem>
                                        <MenuItem value={"gallery"}>
                                          Gallery
                                        </MenuItem>
                                        <MenuItem value={"contact"}>
                                          Contact
                                        </MenuItem>
                                        <MenuItem value={"support"}>
                                          Support
                                        </MenuItem>
                                        <MenuItem value={"subscribe"}>
                                          Subscribe
                                        </MenuItem>
                                        <MenuItem value={"links"}>
                                          Links
                                        </MenuItem>
                                        <MenuItem value={"share"}>
                                          Share
                                        </MenuItem>
                                        <MenuItem value={"url"}>
                                          Url
                                        </MenuItem>
                                      </Select>
                                      {data.headerButtonLink === 'url' && <TextField
                                        label="Url"
                                        type="text"
                                        variant="outlined"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              www.
                                            </InputAdornment>
                                          ),
                                        }}
                                        fullWidth
                                        margin="normal"
                                        defaultValue={data.headerButtonUrl}
                                        size="small"
                                        onChange={(e) => {
                                          handleChange(e, ["headerButtonUrl"]);
                                        }}
                                      />}
                                    </FormControl>
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="About"
                                  handleToggle={toggleSection}
                                  order="about"
                                  value="isAboutShown"
                                >
                                  <div className="form-group">
                                    <TextField
                                      defaultValue={data.aboutTitle}
                                      label="Section title"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      onChange={(e) => {
                                        handleChange(e, ["aboutTitle"]);
                                      }}
                                    />
                                    <h4>Personal Info</h4>
                                    <TextField
                                      defaultValue={data.intro}
                                      label="Tell about yourself"
                                      type="text"
                                      multiline
                                      rows={5}
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      onChange={(e) => {
                                        handleChange(e, ["intro"]);
                                      }}
                                    />
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      component="label"
                                    >
                                      {data.profileImageAbout ? (
                                        <img
                                          src={data.profileImageAbout}
                                          alt="Profile Image"
                                          style={{ height: "40px" }}
                                        />
                                      ) : (
                                        <span>Upload Image</span>
                                      )}
                                      <input
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        onChange={(e) => {
                                          handleImageChange(e, [
                                            "profileImageAbout",
                                          ]);
                                        }}
                                      />
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        removeImage(["profileImageAbout"]);
                                      }}
                                    >
                                      <CancelIcon />
                                    </Button>
                                    <h4>Skills</h4>
                                    {data.bars.map((bar, i) => {
                                      return (
                                        <Grid
                                          container
                                          direction="row"
                                          alignItems="flex-start"
                                          key={i}
                                        >
                                          <TextField
                                            label="Skill"
                                            type="text"
                                            variant="outlined"
                                            margin="normal"
                                            size="small"
                                            defaultValue={bar.title}
                                            onChange={(e) => {
                                              handleChange(e, [
                                                "bars",
                                                i,
                                                "title",
                                              ]);
                                            }}
                                          />
                                          <TextField
                                            label="Level"
                                            type="number"
                                            variant="outlined"
                                            margin="normal"
                                            size="small"
                                            defaultValue={bar.percentage}
                                            InputProps={{
                                              inputProps: { min: 0, max: 100 },
                                            }}
                                            onChange={(e) => {
                                              handleChange(e, [
                                                "bars",
                                                i,
                                                "percentage",
                                              ]);
                                            }}
                                          />
                                        </Grid>
                                      );
                                    })}
                                    <h4>Button</h4>
                                    <TextField
                                      label="Button text"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.downloadCV}
                                      onChange={(e) => {
                                        handleChange(e, ["downloadCV"]);
                                      }}
                                    />
                                    <TextField
                                      label="Button Link"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.cvLink}
                                      onChange={(e) => {
                                        handleChange(e, ["cvLink"]);
                                      }}
                                    />
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Services"
                                  handleToggle={toggleSection}
                                  order="services"
                                  value="isServicesShown"
                                >
                                  <div className="form-group">
                                    <TextField
                                      label="Section title"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.servicesTitle}
                                      onChange={(e) => {
                                        handleChange(e, ["servicesTitle"]);
                                      }}
                                    />
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                      width="100%"
                                    >
                                      <h4>Services</h4>
                                      <Button
                                        className="mt-auto mb-auto"
                                        onClick={() => {
                                          addInput("servicesData", 999);
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    {data.servicesData.map((service, i) => {
                                      return (
                                        <Grid key={service.id + i}>
                                          <h6>Option {i + 1}</h6>
                                          <TextField
                                            label="Service title"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            size="small"
                                            defaultValue={service.title}
                                            onChange={(e) => {
                                              handleChange(e, [
                                                "servicesData",
                                                i,
                                                "title",
                                              ]);
                                            }}
                                          />
                                          <TextField
                                            label="Service description"
                                            type="text"
                                            multiline
                                            rows={3}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            size="small"
                                            defaultValue={service.content}
                                            onChange={(e) => {
                                              handleChange(e, [
                                                "servicesData",
                                                i,
                                                "content",
                                              ]);
                                            }}
                                          />
                                          <TextField
                                            label="Card Color"
                                            type="color"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            size="small"
                                            defaultValue={service.color}
                                            onChange={(e) => {
                                              handleChange(e, [
                                                "servicesData",
                                                i,
                                                "color",
                                              ]);
                                            }}
                                          />
                                          <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                          >
                                            <div>
                                              <Button
                                                color="primary"
                                                variant="outlined"
                                                component="label"
                                              >
                                                {service.icon ? (
                                                  <img
                                                    src={service.icon}
                                                    alt="Service Image"
                                                    style={{ height: "40px" }}
                                                  />
                                                ) : (
                                                  <span>Upload Image</span>
                                                )}
                                                <input
                                                  type="file"
                                                  accept="image/*"
                                                  hidden
                                                  onChange={(e) => {
                                                    handleImageChange(e, [
                                                      "servicesData",
                                                      i,
                                                      "icon",
                                                    ]);
                                                  }}
                                                />
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  removeImage([
                                                    "servicesData",
                                                    i,
                                                    "icon",
                                                  ]);
                                                }}
                                              >
                                                <CancelIcon />
                                              </Button>
                                            </div>
                                            <Button
                                              className="mt-auto mb-auto"
                                              onClick={() => {
                                                removeInput("servicesData", i);
                                              }}
                                              style={{ float: "right" }}
                                            >
                                              <DeleteIcon />
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      );
                                    })}
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Experience"
                                  handleToggle={toggleSection}
                                  order="experiences"
                                  value="isExperienceShown"
                                >
                                  <div className="form-group">
                                    <TextField
                                      label="Section title"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.experienceTitle}
                                      onChange={(e) => {
                                        handleChange(e, ["experienceTitle"]);
                                      }}
                                    />
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                    >
                                      <h4>Experience</h4>
                                      <Button
                                        className="mt-auto mb-auto"
                                        onClick={() => {
                                          addInput("experienceData", 999);
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    {data &&
                                      data.experienceData.map(
                                        (experience, i) => {
                                          return (
                                            <Grid key={experience.id + i}>
                                              <TextField
                                                label="Experience title"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                defaultValue={experience.title}
                                                onChange={(e) => {
                                                  handleChange(e, [
                                                    "experienceData",
                                                    i,
                                                    "title",
                                                  ]);
                                                }}
                                              />
                                              <TextField
                                                label="Experience description"
                                                type="text"
                                                multiline
                                                rows={3}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                defaultValue={
                                                  experience.content
                                                }
                                                onChange={(e) => {
                                                  handleChange(e, [
                                                    "experienceData",
                                                    i,
                                                    "content",
                                                  ]);
                                                }}
                                              />
                                              <Grid
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="flex-start"
                                              >
                                                <TextField
                                                  label="Experience date"
                                                  type="text"
                                                  variant="outlined"
                                                  margin="normal"
                                                  size="small"
                                                  defaultValue={
                                                    experience.years
                                                  }
                                                  onChange={(e) => {
                                                    handleChange(e, [
                                                      "experienceData",
                                                      i,
                                                      "years",
                                                    ]);
                                                  }}
                                                />
                                                <Button
                                                  className="mt-auto mb-auto"
                                                  onClick={() => {
                                                    removeInput(
                                                      "experienceData",
                                                      i
                                                    );
                                                  }}
                                                >
                                                  <DeleteIcon />
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          );
                                        }
                                      )}
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                    >
                                      <h4>Education</h4>
                                      <Button
                                        className="mt-auto mb-auto"
                                        onClick={() => {
                                          addInput("educationData", 999);
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    {data &&
                                      data.educationData.map((education, i) => {
                                        return (
                                          <Grid key={education.id + i}>
                                            <TextField
                                              label="Education title"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={education.title}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "educationData",
                                                  i,
                                                  "title",
                                                ]);
                                              }}
                                            />
                                            <TextField
                                              label="Education description"
                                              type="text"
                                              multiline
                                              rows={3}
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={education.content}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "educationData",
                                                  i,
                                                  "content",
                                                ]);
                                              }}
                                            />
                                            <Grid
                                              container
                                              direction="row"
                                              justify="space-between"
                                              alignItems="flex-start"
                                            >
                                              <TextField
                                                label="Education date"
                                                type="text"
                                                variant="outlined"
                                                margin="normal"
                                                size="small"
                                                defaultValue={education.years}
                                                onChange={(e) => {
                                                  handleChange(e, [
                                                    "educationData",
                                                    i,
                                                    "years",
                                                  ]);
                                                }}
                                              />
                                              <Button
                                                className="mt-auto mb-auto"
                                                onClick={() => {
                                                  removeInput(
                                                    "educationData",
                                                    i
                                                  );
                                                }}
                                              >
                                                <DeleteIcon />
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Portfolio"
                                  handleToggle={toggleSection}
                                  order="works"
                                  value="isPortfolioShown"
                                >
                                  <div className="form-group col">
                                    <TextField
                                      defaultValue={data.portfolioTitle}
                                      label="Section title"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      onChange={(e) => {
                                        handleChange(e, ["portfolioTitle"]);
                                      }}
                                    />
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                    >
                                      <h4>Portfolio</h4>
                                      <Button
                                        className="mt-auto mb-auto"
                                        onClick={() => {
                                          addInput("works", 999);
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    {data &&
                                      data.works.map((post, i) => {
                                        return (
                                          <Grid
                                            style={{
                                              border: "1px solid black",
                                              padding: 10,
                                              marginTop: 10,
                                              borderRadius: 5,
                                            }}
                                            key={post.id + i}
                                          >
                                            <TextField
                                              label="work title"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={post.title}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "works",
                                                  i,
                                                  "title",
                                                ]);
                                              }}
                                            />

                                            <TextField
                                              label="category"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={post.category}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "works",
                                                  i,
                                                  "category",
                                                ]);
                                              }}
                                            />

                                            {/*<TextField label="Post image" type='text' variant="outlined" fullWidth margin='normal' size='small' defaultValue={post.image}*/}
                                            {/*           onChange={(e) => { handleChange(e, ['allBlogs', i, 'image']) }}*/}
                                            {/*/>*/}
                                            <Grid
                                              container
                                              direction="row"
                                              justify="space-between"
                                              alignItems="center"
                                            >
                                              <div>
                                                <Button
                                                  color="primary"
                                                  variant="outlined"
                                                  component="label"
                                                >
                                                  {post.image ? (
                                                    <img
                                                      src={post.image}
                                                      alt="Service Image"
                                                      style={{ height: "40px" }}
                                                    />
                                                  ) : (
                                                    <span>Upload Image</span>
                                                  )}
                                                  <input
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(e) => {
                                                      handleImageChange(e, [
                                                        "works",
                                                        i,
                                                        "image",
                                                      ]);
                                                    }}
                                                  />
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    removeImage([
                                                      "works",
                                                      i,
                                                      "image",
                                                    ]);
                                                  }}
                                                >
                                                  <CancelIcon />
                                                </Button>
                                              </div>
                                            </Grid>

                                            <TextField
                                              label="Description"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={post.text}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "works",
                                                  i,
                                                  "text",
                                                ]);
                                              }}
                                            />

                                            <TextField
                                              label="Youtube Link"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={post.video}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "works",
                                                  i,
                                                  "video",
                                                ]);
                                              }}
                                            />

                                            <TextField
                                              label="Link URL"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={post.link}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "works",
                                                  i,
                                                  "link",
                                                ]);
                                              }}
                                            />

                                            <TextField
                                              label="Link Text"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={post.linkText}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "works",
                                                  i,
                                                  "linkText",
                                                ]);
                                              }}
                                            />

                                            <Grid
                                              container
                                              direction="row"
                                              justify="space-between"
                                              alignItems="flex-start"
                                            >
                                              <span></span>
                                              <Button
                                                className="mt-auto mb-auto"
                                                onClick={() => {
                                                  removeInput("works", i);
                                                }}
                                              >
                                                <DeleteIcon />
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Pricing"
                                  handleToggle={toggleSection}
                                  order="pricing"
                                  value="isPricingShown"
                                >
                                  <div className="form-group">
                                    <TextField
                                      label="Section title"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.pricingTitle}
                                      onChange={(e) => {
                                        handleChange(e, ["pricingTitle"]);
                                      }}
                                    />
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                    >
                                      <h4>Pricing</h4>
                                      <Button
                                        className="mt-auto mb-auto"
                                        onClick={() => {
                                          addInput("priceboxData", 999);
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    {data &&
                                      data.priceboxData.map((price, i) => {
                                        return (
                                          <Grid key={price.id + i}>
                                            <h4>Option {i + 1}</h4>
                                            <TextField
                                              label="Pricing title"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={price.title}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "priceboxData",
                                                  i,
                                                  "title",
                                                ]);
                                              }}
                                            />
                                            <TextField
                                              label="Pricing description"
                                              type="text"
                                              multiline
                                              rows={3}
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={
                                                price.content[0].text
                                              }
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "priceboxData",
                                                  i,
                                                  "content",
                                                  0,
                                                  "text",
                                                ]);
                                              }}
                                            />
                                            <TextField
                                              label="Pricing underline"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={
                                                price.content[1].text
                                              }
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "priceboxData",
                                                  i,
                                                  "content",
                                                  1,
                                                  "text",
                                                ]);
                                              }}
                                            />
                                            <TextField
                                              label="Button Text"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={price.buttonText}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "priceboxData",
                                                  i,
                                                  "buttonText",
                                                ]);
                                              }}
                                            />
                                            <TextField
                                              label="Button Link"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={price.buttonLink}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "priceboxData",
                                                  i,
                                                  "buttonLink",
                                                ]);
                                              }}
                                            />
                                            <TextField
                                              label="Badge"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={price.badge}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "priceboxData",
                                                  i,
                                                  "badge",
                                                ]);
                                              }}
                                            />
                                            <TextField
                                              label="Price"
                                              type="number"
                                              variant="outlined"
                                              margin="normal"
                                              size="small"
                                              InputProps={{
                                                inputProps: { min: 0 },
                                              }}
                                              defaultValue={price.price}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "priceboxData",
                                                  i,
                                                  "price",
                                                ]);
                                              }}
                                            />
                                            <Grid>
                                              <FormControl
                                                variant="outlined"
                                                className={
                                                  classes.formControl +
                                                  " mt-2 mr-2"
                                                }
                                              >
                                                <InputLabel id="recurring-label">
                                                  Recurring
                                                </InputLabel>
                                                <Select
                                                  labelId="recurring-label"
                                                  id="recurring"
                                                  label="Recurring"
                                                  value={
                                                    price.recurringPaymentPeriod
                                                  }
                                                  onChange={(e) => {
                                                    handleChange(e, [
                                                      "priceboxData",
                                                      i,
                                                      "recurringPaymentPeriod",
                                                    ]);
                                                  }}
                                                >
                                                  <MenuItem value={"Month"}>
                                                    Month
                                                  </MenuItem>
                                                  <MenuItem value={"Year"}>
                                                    Year
                                                  </MenuItem>
                                                  <MenuItem value={"Life Time"}>
                                                    Life Time
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                              <FormControl
                                                variant="outlined"
                                                className={
                                                  classes.formControl + " mt-2"
                                                }
                                              >
                                                <InputLabel id="currency-label">
                                                  Currency
                                                </InputLabel>
                                                <Select
                                                  labelId="currency-label"
                                                  id="currency"
                                                  label="Currency"
                                                  value={price.CurrencySymbol}
                                                  onChange={(e) => {
                                                    handleChange(e, [
                                                      "priceboxData",
                                                      i,
                                                      "CurrencySymbol",
                                                    ]);
                                                  }}
                                                >
                                                  <MenuItem value={"$"}>
                                                    $
                                                  </MenuItem>
                                                  <MenuItem value={"\u20AA"}>
                                                    {"\u20AA"}
                                                  </MenuItem>
                                                  <MenuItem value={"\u20AC"}>
                                                    {"\u20AC"}
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                            <Grid
                                              container
                                              direction="row"
                                              justify="space-between"
                                              alignItems="flex-start"
                                            >
                                              <div>
                                                <Button
                                                  color="primary"
                                                  variant="outlined"
                                                  component="label"
                                                  className="mt-2"
                                                >
                                                  {price.icon ? (
                                                    <img
                                                      src={price.icon}
                                                      alt="Price Image"
                                                      style={{ height: "40px" }}
                                                    />
                                                  ) : (
                                                    <span>Upload Image</span>
                                                  )}
                                                  <input
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(e) => {
                                                      handleImageChange(e, [
                                                        "priceboxData",
                                                        i,
                                                        "icon",
                                                      ]);
                                                    }}
                                                  />
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    removeImage([
                                                      "priceboxData",
                                                      i,
                                                      "icon",
                                                    ]);
                                                  }}
                                                >
                                                  <CancelIcon />
                                                </Button>
                                              </div>
                                              <Button
                                                className="mt-auto mb-auto"
                                                onClick={() => {
                                                  removeInput(
                                                    "priceboxData",
                                                    i
                                                  );
                                                }}
                                              >
                                                <DeleteIcon />
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Reviews"
                                  handleToggle={toggleSection}
                                  order="testimonials"
                                  value="isTestimonialShown"
                                >
                                  <div className="form-group">
                                    <TextField
                                      label="Section title"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.testimonialTitle}
                                      onChange={(e) => {
                                        handleChange(e, ["testimonialTitle"]);
                                      }}
                                    />
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                    >
                                      <h4>Reviews</h4>
                                      <Button
                                        className="mt-auto mb-auto"
                                        onClick={() => {
                                          addInput("testimonialData", 999);
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    {data &&
                                      data.testimonialData.map((review, i) => {
                                        return (
                                          <Grid key={review.id + i}>
                                            <TextField
                                              label="User Name"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={review.userName}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "testimonialData",
                                                  i,
                                                  "userName",
                                                ]);
                                              }}
                                            />
                                            <TextField
                                              label="Sub title"
                                              type="text"
                                              multiline
                                              rows={3}
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={review.subtitle}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "testimonialData",
                                                  i,
                                                  "subtitle",
                                                ]);
                                              }}
                                            />
                                            <TextField
                                              label="Review"
                                              type="text"
                                              multiline
                                              rows={4}
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={review.review}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "testimonialData",
                                                  i,
                                                  "review",
                                                ]);
                                              }}
                                            />
                                            <Grid
                                              container
                                              direction="row"
                                              justify="space-between"
                                              alignItems="flex-start"
                                            >
                                              <div>
                                                <Button
                                                  color="primary"
                                                  variant="outlined"
                                                  component="label"
                                                  className="mt-2"
                                                >
                                                  {review.avatar ? (
                                                    <img
                                                      src={review.avatar}
                                                      alt="Avatar"
                                                      style={{ height: "40px" }}
                                                    />
                                                  ) : (
                                                    <span>Upload Image</span>
                                                  )}
                                                  <input
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    name={`review${i}Image`}
                                                    ref={register()}
                                                    onChange={(e) => {
                                                      handleImageChange(e, [
                                                        "testimonialData",
                                                        i,
                                                        "avatar",
                                                      ]);
                                                    }}
                                                  />
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    removeImage([
                                                      "testimonialData",
                                                      i,
                                                      "avatar",
                                                    ]);
                                                  }}
                                                >
                                                  <CancelIcon />
                                                </Button>
                                              </div>
                                              <Button
                                                className="mt-auto mb-auto"
                                                onClick={() => {
                                                  removeInput(
                                                    "testimonialData",
                                                    i
                                                  );
                                                }}
                                              >
                                                <DeleteIcon />
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Brand Logos"
                                  handleToggle={toggleSection}
                                  order="brandlogos"
                                  value="isBrandLogosShown"
                                >
                                  <div className="form-group">
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                    >
                                      <h3>Brand Logos</h3>
                                      <Button
                                        className="mt-auto mb-auto"
                                        onClick={() => {
                                          addInput("brandlogoData", 999);
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    {data &&
                                      data.brandlogoData.map((logo, i) => {
                                        return (
                                          <Grid key={logo.id + i}>
                                            <Button
                                              color="primary"
                                              variant="outlined"
                                              component="label"
                                            >
                                              {logo.image ? (
                                                <img
                                                  src={logo.image}
                                                  alt="brand logo"
                                                  style={{ height: "40px" }}
                                                />
                                              ) : (
                                                <span>Upload Image</span>
                                              )}
                                              <input
                                                type="file"
                                                accept="image/*"
                                                hidden
                                                name={`brand${i}logo`}
                                                ref={register()}
                                                onChange={(e) => {
                                                  handleImageChange(e, [
                                                    "brandlogoData",
                                                    i,
                                                    "image",
                                                  ]);
                                                }}
                                              />
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                removeInput("brandlogoData", i);
                                              }}
                                            >
                                              <DeleteIcon />
                                            </Button>
                                          </Grid>
                                        );
                                      })}
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Posts"
                                  handleToggle={toggleSection}
                                  order="posts"
                                  value="isBlogShown"
                                >
                                  <div className="form-group col">
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                    >
                                      <h4>Posts</h4>
                                      <Button
                                        className="mt-auto mb-auto"
                                        onClick={() => {
                                          addInput("allBlogs", 999);
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    {data &&
                                      data.allBlogs.map((post, i) => {
                                        return (
                                          <Grid
                                            style={{
                                              border: "1px solid black",
                                              padding: 10,
                                              marginTop: 10,
                                              borderRadius: 5,
                                            }}
                                            key={post.id + i}
                                          >
                                            <TextField
                                              label="Post title"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={post.title}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "allBlogs",
                                                  i,
                                                  "title",
                                                ]);
                                              }}
                                            />

                                            {/*<TextField label="Post image" type='text' variant="outlined" fullWidth margin='normal' size='small' defaultValue={post.image}*/}
                                            {/*           onChange={(e) => { handleChange(e, ['allBlogs', i, 'image']) }}*/}
                                            {/*/>*/}
                                            <Grid
                                              container
                                              direction="row"
                                              justify="space-between"
                                              alignItems="center"
                                            >
                                              <div>
                                                <Button
                                                  color="primary"
                                                  variant="outlined"
                                                  component="label"
                                                >
                                                  {post.image ? (
                                                    <img
                                                      src={post.image}
                                                      alt="Service Image"
                                                      style={{ height: "40px" }}
                                                    />
                                                  ) : (
                                                    <span>Upload Image</span>
                                                  )}
                                                  <input
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(e) => {
                                                      handleImageChange(e, [
                                                        "allBlogs",
                                                        i,
                                                        "image",
                                                      ]);
                                                    }}
                                                  />
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    removeImage([
                                                      "allBlogs",
                                                      i,
                                                      "image",
                                                    ]);
                                                  }}
                                                >
                                                  <CancelIcon />
                                                </Button>
                                              </div>
                                            </Grid>

                                            <TextField
                                              label="Date"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={post.date}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "allBlogs",
                                                  i,
                                                  "date",
                                                ]);
                                              }}
                                            />

                                            <TextField
                                              label="Author"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={post.author}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "allBlogs",
                                                  i,
                                                  "author",
                                                ]);
                                              }}
                                            />

                                            <TextField
                                              label="Category"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={post.category}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "allBlogs",
                                                  i,
                                                  "category",
                                                ]);
                                              }}
                                            />

                                            <RadioGroup
                                              value={
                                                data.allBlogs[i].isBlogExternal
                                              }
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "allBlogs",
                                                  i,
                                                  "isBlogExternal",
                                                ]);
                                              }}
                                              row
                                              aria-label="position"
                                              name="position"
                                              defaultValue="top"
                                            >
                                              <Grid
                                                container
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                              >
                                                <FormControlLabel
                                                  value="false"
                                                  control={
                                                    <Radio color="primary" />
                                                  }
                                                  label="Show post on this website"
                                                  labelPlacement="top"
                                                />

                                                <FormControlLabel
                                                  value="true"
                                                  control={
                                                    <Radio color="primary" />
                                                  }
                                                  label="Send to external website"
                                                  labelPlacement="top"
                                                />
                                              </Grid>
                                            </RadioGroup>

                                            {data.allBlogs[i].isBlogExternal !==
                                              "true" && (
                                                <Typography variant="caption">
                                                  For showing posts inside your
                                                page,{" "}
                                                  <a href="https://sumbli.com/submit">
                                                    create post on sumbli
                                                </a>{" "}
                                                and paste post's url here.
                                                </Typography>
                                              )}

                                            <TextField
                                              label="Link"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={post.filesource}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "allBlogs",
                                                  i,
                                                  "filesource",
                                                ]);
                                              }}
                                            />

                                            <Grid
                                              container
                                              direction="row"
                                              justify="space-between"
                                              alignItems="flex-start"
                                            >
                                              <span></span>
                                              <Button
                                                className="mt-auto mb-auto"
                                                onClick={() => {
                                                  removeInput("allBlogs", i);
                                                }}
                                              >
                                                <DeleteIcon />
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Gallery"
                                  handleToggle={toggleSection}
                                  order="gallery"
                                  value="isGalleryShown"
                                >
                                  <div className="form-group">
                                    <TextField
                                      label="Section title"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.galleryTitle}
                                      onChange={(e) => {
                                        handleChange(e, ["galleryTitle"]);
                                      }}
                                    />
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                    >
                                      <h3>Gallery Images</h3>
                                      <Button
                                        className="mt-auto mb-auto"
                                        onClick={() => {
                                          addInput("galleryData", 999);
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    {data &&
                                      data.galleryData.map((picture, i) => {
                                        return (
                                          <Grid key={i}>
                                            <Button
                                              color="primary"
                                              variant="outlined"
                                              component="label"
                                            >
                                              {picture.image ? (
                                                <img
                                                  src={picture.image}
                                                  alt=""
                                                  style={{ height: "40px" }}
                                                />
                                              ) : (
                                                <span>Upload Image</span>
                                              )}
                                              <input
                                                type="file"
                                                accept="image/*"
                                                hidden
                                                onChange={(e) => {
                                                  handleImageChange(e, [
                                                    "galleryData",
                                                    i,
                                                    "image",
                                                  ]);
                                                }}
                                              />
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                removeInput("galleryData", i);
                                              }}
                                            >
                                              <DeleteIcon />
                                            </Button>
                                          </Grid>
                                        );
                                      })}
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Contact"
                                  handleToggle={toggleSection}
                                  order="contact"
                                  value="isContactShown"
                                >
                                  <div className="form-group">
                                    <TextField
                                      label="Section title"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.contactTitle}
                                      onChange={(e) => {
                                        handleChange(e, ["contactTitle"]);
                                      }}
                                    />
                                    <TextField
                                      label="Section Text"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.contactText}
                                      onChange={(e) => {
                                        handleChange(e, ["contactText"]);
                                      }}
                                    />
                                    <TextField
                                      label="Email"
                                      type="email"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.myEmail}
                                      onChange={(e) => {
                                        handleChange(e, ["myEmail"]);
                                      }}
                                    />
                                    <TextField
                                      label="Phone Number"
                                      type="tel"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.myPhone}
                                      onChange={(e) => {
                                        handleChange(e, ["myPhone"]);
                                      }}
                                    />
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Share"
                                  handleToggle={toggleSection}
                                  order="share"
                                  value="isShareShown"
                                >
                                  <div className="form-group">
                                    <TextField
                                      label="Section title"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.shareTitle}
                                      onChange={(e) => {
                                        handleChange(e, ["shareTitle"]);
                                      }}
                                    />

                                    <TextField
                                      label="Share URL"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      value={shareUrl}
                                      onChange={(e) => {
                                        setShareUrl(e.target.value);
                                        handleChange(e, ["shareUrl"]);
                                      }}
                                    />
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Support"
                                  handleToggle={toggleSection}
                                  order="support"
                                  value="isSupportShown"
                                >
                                  <div className="form-group">
                                    <TextField
                                      label="Section title"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.supportTitle}
                                      onChange={(e) => {
                                        handleChange(e, ["supportTitle"]);
                                      }}
                                    />
                                    <TextField
                                      label="Support Text"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.donationText}
                                      onChange={(e) => {
                                        handleChange(e, ["donationText"]);
                                      }}
                                    />
                                    <RadioGroup
                                      value={data.isSupportExternal}
                                      onChange={(e) => {
                                        handleChange(e, ["isSupportExternal"]);
                                      }}
                                      row
                                      aria-label="position"
                                      name="position"
                                      defaultValue="top"
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                      >
                                        <FormControlLabel
                                          value="false"
                                          control={<Radio color="primary" />}
                                          label="Use Paypal Embed payment Button."
                                          labelPlacement="top"
                                        />

                                        <FormControlLabel
                                          value="true"
                                          control={<Radio color="primary" />}
                                          label="Send to external website"
                                          labelPlacement="top"
                                        />
                                      </Grid>
                                    </RadioGroup>

                                    <>
                                      {data.isSupportExternal === "true" ? (
                                        <>
                                          <TextField
                                            label="External donation link URL"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            size="small"
                                            defaultValue={data.donationUrl}
                                            onChange={(e) => {
                                              handleChange(e, ["donationUrl"]);
                                            }}
                                          />

                                          <TextField
                                            label="Link Text"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            defaultValue={data.donationUrlText}
                                            margin="normal"
                                            size="small"
                                            onChange={(e) => {
                                              handleChange(e, [
                                                "donationUrlText",
                                              ]);
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <div>
                                          <Typography>
                                            <a
                                              target="_blank"
                                              href="https://www.paypal.com/donate/buttons"
                                            >
                                              Create Paypal Donation Button
                                            </a>{" "}
                                            and paste the url
                                          </Typography>

                                          <TextField
                                            label="Paypal button URL"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            size="small"
                                            defaultValue={data.paypalUrl}
                                            onChange={(e) => {
                                              handleChange(e, ["paypalUrl"]);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </>
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Links"
                                  handleToggle={toggleSection}
                                  order="links"
                                  value="isLinksShown"
                                >
                                  <div className="form-group">
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="flex-start"
                                    >
                                      <h4>Links</h4>
                                      <Button
                                        className="mt-auto mb-auto"
                                        onClick={() => {
                                          addInput("bigLinks", 999);
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    {data &&
                                      data.bigLinks.map((link, i) => {
                                        return (
                                          <Grid
                                            style={{
                                              border: "1px solid black",
                                              padding: 10,
                                              marginTop: 10,
                                              borderRadius: 5,
                                            }}
                                            key={link.id + i}
                                          >
                                            <TextField
                                              label="Link Title"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={link.title}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "bigLinks",
                                                  i,
                                                  "title",
                                                ]);
                                              }}
                                            />

                                            <TextField
                                              label="URL"
                                              type="text"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                              size="small"
                                              defaultValue={link.url}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "bigLinks",
                                                  i,
                                                  "url",
                                                ]);
                                              }}
                                            />

                                            <InputLabel id="demo-simple-select-label">Icon</InputLabel>
                                            <Select label='Icon'
                                              defaultValue={link.icon}
                                              onChange={(e) => {
                                                handleChange(e, [
                                                  "bigLinks",
                                                  i,
                                                  "icon",
                                                ]);
                                              }}
                                            >
                                              <MenuItem value="no-icon">
                                                <ListItemText primary="No Icon" />
                                              </MenuItem>
                                              <MenuItem value="icon-social-instagram">
                                                <ListItemIcon>
                                                  <i className='icon-social-instagram' />
                                                </ListItemIcon>
                                              </MenuItem>
                                              <MenuItem value="icon-social-twitter">
                                                <ListItemIcon>
                                                  <i className='icon-social-twitter' />
                                                </ListItemIcon>
                                              </MenuItem>
                                              <MenuItem value="icon-social-facebook">
                                                <ListItemIcon>
                                                  <i className='icon-social-facebook' />
                                                </ListItemIcon>
                                              </MenuItem>
                                              <MenuItem value="icon-social-linkedin">
                                                <ListItemIcon>
                                                  <i className='icon-social-linkedin' />
                                                </ListItemIcon>
                                              </MenuItem>
                                              <MenuItem value="icon-social-github">
                                                <ListItemIcon>
                                                  <i className='icon-social-github' />
                                                </ListItemIcon>
                                              </MenuItem>
                                              <MenuItem value="icon-social-youtube">
                                                <ListItemIcon>
                                                  <i className='icon-social-youtube' />
                                                </ListItemIcon>
                                              </MenuItem>
                                              <MenuItem value="icon-globe">
                                                <ListItemIcon>
                                                  <i className='icon-globe' />
                                                </ListItemIcon>
                                              </MenuItem>
                                              <MenuItem value="icon-envelope">
                                                <ListItemIcon>
                                                  <i className='icon-envelope' />
                                                </ListItemIcon>
                                              </MenuItem>
                                            </Select>

                                            {/*<TextField label="Post image" type='text' variant="outlined" fullWidth margin='normal' size='small' defaultValue={link.image}*/}
                                            {/*           onChange={(e) => { handleChange(e, ['allBlogs', i, 'image']) }}*/}
                                            {/*/>*/}
                                            {/* <Grid
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="center"
                                              >
                                                <div>
                                                  <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    component="label"
                                                  >
                                                    {link.image ? (
                                                      <img
                                                        src={link.image}
                                                        alt="Service Image"
                                                        style={{ height: "40px" }}
                                                      />
                                                    ) : (
                                                        <span>Upload Image</span>
                                                      )}
                                                    <input
                                                      type="file"
                                                      accept="image/*"
                                                      hidden
                                                      onChange={(e) => {
                                                        handleImageChange(e, [
                                                          "bigLinks",
                                                          i,
                                                          "image",
                                                        ]);
                                                      }}
                                                    />
                                                  </Button>
                                                  <Button
                                                    onClick={() => {
                                                      removeImage([
                                                        "bigLinks",
                                                        i,
                                                        "image",
                                                      ]);
                                                    }}
                                                  >
                                                    <CancelIcon />
                                                  </Button>
                                                </div>
                                              </Grid> */}

                                            <Grid
                                              container
                                              direction="row"
                                              justify="space-between"
                                              alignItems="flex-start"
                                            >
                                              <span></span>
                                              <Button
                                                className="mt-auto mb-auto"
                                                onClick={() => {
                                                  removeInput("bigLinks", i);
                                                }}
                                              >
                                                <DeleteIcon />
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                  </div>
                                </AccordionItem>
                                <AccordionItem
                                  title="Subscribe"
                                  handleToggle={toggleSection}
                                  order="subscribe"
                                  value="isSubscribeShown"
                                >
                                  <div className="form-group">
                                    <TextField
                                      label="Section title"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.subscribeTitle}
                                      onChange={(e) => {
                                        handleChange(e, ["subscribeTitle"]);
                                      }}
                                    />
                                    <TextField
                                      label="Subscribe text"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      size="small"
                                      defaultValue={data.subscribeText}
                                      onChange={(e) => {
                                        handleChange(e, ["subscribeText"]);
                                      }}
                                    />
                                    <Typography variant="caption">* Subscribers list will be shown in your <a href="/admin">Admin Page</a></Typography>
                                    {/*<TextField label="Share URL" type='text' variant="outlined" fullWidth margin='normal' size='small' value={shareUrl}*/}
                                    {/*           onChange={(e) => { setShareUrl(e.target.value); handleChange(e, ['shareUrl']);  }}*/}
                                    {/*/>*/}
                                  </div>
                                </AccordionItem>
                              </Reorder>
                              <h3 style={{ color: "#ffffff" }}>Options</h3>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2a-content"
                                  id="panel2a-header"
                                >
                                  <Typography className={classes.heading}>
                                    Custom Domain Address
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div>
                                    <br />
                                    <Typography variant="h5">
                                      Steps to show this website on your domain
                                    </Typography>
                                    <br />
                                    <Typography variant="h5">
                                      {" "}
                                      1. Have or buy a domain
                                    </Typography>
                                    <Typography>
                                      If you want to buy new domain you can buy
                                      one on any domain buying websites like{" "}
                                      <a
                                        target="_blank"
                                        href={"https://www.namecheap.com/"}
                                      >
                                        namecheap
                                      </a>{" "}
                                      and{" "}
                                      <a
                                        target="_blank"
                                        href={"https://godaddy.com/"}
                                      >
                                        godaddy
                                      </a>
                                      .
                                    </Typography>

                                    <br />
                                    <Typography variant="h5">
                                      {" "}
                                      2. Show this website on your domain
                                    </Typography>
                                    <br />
                                    <Typography variant="h6">
                                      2.1 Hosting HTML File
                                    </Typography>
                                    <Typography>
                                      1. Download{" "}
                                      <a href="" onClick={downloadHTML}>
                                        this HTML file
                                      </a>
                                      .
                                    </Typography>
                                    <div style={{}}>
                                      <Typography>
                                        2. Host your HTML file on hosting
                                        service like{" "}
                                        <a
                                          target="_blank"
                                          href="https://www.youtube.com/watch?v=w7xKZ5PWizs&ab_channel=SameerSaini"
                                        >
                                          Firebase
                                        </a>{" "}
                                        &{" "}
                                        <a
                                          target="_blank"
                                          href={"https://htmlsave.com/"}
                                        >
                                          htmlsave.com
                                        </a>
                                        .
                                      </Typography>
                                      <Typography>
                                        3. Refer the domain to your hosting
                                        service where the HTML file is hosted.
                                        The way you do this depends on the
                                        hosting service and where you bought
                                        your domain.
                                      </Typography>
                                    </div>
                                    <br />
                                    <Typography variant="h6">
                                      {" "}
                                      2.2 Redierect
                                    </Typography>
                                    <Typography>
                                      Redirect the domain you bought to your
                                      page (ex: https://onlinepag.es/myname).
                                    </Typography>
                                    <Typography>
                                      You can choose the option 'unmasked' so
                                      when ever you go to yourdomain.com it will
                                      change the URL to your page.
                                    </Typography>
                                    <Typography>
                                      Also you can choose the option 'masked' so
                                      when ever you go to yourdomain.com you
                                      could see your page without change the url
                                      (the problem is you can't use https for
                                      it).
                                    </Typography>
                                    <br />
                                    <Typography variant="h6">
                                      {" "}
                                      2.3 Download the source code of the
                                      webpage
                                    </Typography>
                                    <Typography>
                                      You can{" "}
                                      <a href="/contact" target="_blank">
                                        contact us
                                      </a>{" "}
                                      and ask for the source code for your
                                      website (The website made with{" "}
                                      <a
                                        href="https://reactjs.org/"
                                        target="_blank"
                                      >
                                        ReactJS
                                      </a>
                                      ).
                                    </Typography>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                            <br />
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              style={{ marginBottom: 20 }}
                            >
                              {updating ? (
                                <LinearProgress
                                  color="secondary"
                                  style={{
                                    marginTop: "19px",
                                    marginBottom: "19px",
                                    width: "100%",
                                  }}
                                />
                              ) : (
                                <input
                                  className=" btn btn-default"
                                  type="submit"
                                  value="Save & Publish"
                                />
                              )}
                            </Grid>
                          </form>
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >
                            {data && data.id && data.id.length > 0 && (
                              <a target="_blank" href={"/" + data.id}>
                                View Live Version
                              </a>
                            )}
                          </Grid>
                        </TopBar>
                      </div>
                    </div>
                  </>
                )}
              </main>
            </>
          ) : isLoading ? (
            <div style={{ backgroundColor: "#353353", height: "100vh" }} />
          ) : (
            <Redirect to="/404" />
          )}
        </>
      )}
    </>
  );
}

export default Editor;
