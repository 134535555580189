import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

function Contact({ data }) {
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [sending, setSending] = useState(0);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formdata.name) {
      setError(true);
      setMessage("Name is required");
    } else if (!formdata.email) {
      setError(true);
      setMessage("Email is required");
    } else if (!formdata.subject) {
      setError(true);
      setMessage("Subject is required");
    } else if (!formdata.message) {
      setError(true);
      setMessage("Message is required");
    } else {
      sendMail()
    }
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  const sendMail = () => {
    setSending(1);
    window.Email.send({
      Host: process.env.REACT_APP_EMAIL_HOST,
      Username: process.env.REACT_APP_EMAIL_USER_NAME,
      Password: process.env.REACT_APP_EMAIL_USER_PASSWORD,
      To: data.myEmail,
      From: process.env.REACT_APP_EMAIL,
      Subject: `Email from: ${formdata.name}`,
      Body: `<html><h2>You have received a new email from onlinepag.es</h2><h4>From: ${formdata.name}</h4><strong>${formdata.email} </strong><br /><h3> ${formdata.subject}</h3><p>${formdata.message}</p></html>`
    }).then(() => {
      setError(false);
      setMessage('Your message has been sent!');
    }).catch(() => {
      setError(true);
      setMessage('Something went wrong, email was not sent.');
    }).finally(() => {
      setSending(2)
    });
  }

  return (
    <section id="contact" style={{}}>
      <div className="container">
        <Pagetitle title={data.contactTitle} />
        <div className="row">
          <div className="col-md-4">
            <div className="contact-info">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h3>{data.contactText}</h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                {data.myEmail && <p>
                  Forms not your thing? Contact me at {" "}
                  <a href={'mailto:' + data.myEmail}>{data.myEmail}</a>. 👋
                </p>}
                {data.myPhone && <p>
                  Or call me at : {" "}
                  <a href={'tel:' + data.myPhone}>{data.myPhone}</a>. 📱
                </p>}
              </ScrollAnimation>
            </div>
          </div>

          <div className="col-md-8">
            <form
              id="contact-form"
              className="contact-form mt-6"
              onSubmit={submitHandler}
            >
              <div className="row">
                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="InputName"
                      placeholder="Your name"
                      onChange={handleChange}
                      value={formdata.name}
                    />
                  </div>
                </div>

                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="InputEmail"
                      name="email"
                      placeholder="Email address"
                      onChange={handleChange}
                      value={formdata.email}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="InputSubject"
                      name="subject"
                      placeholder="Subject"
                      onChange={handleChange}
                      value={formdata.subject}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="InputMessage"
                      className="form-control"
                      rows="5"
                      placeholder="Message"
                      onChange={handleChange}
                      value={formdata.message}
                    ></textarea>
                  </div>
                </div>
              </div>
              {sending ? sending === 1 ? <CircularProgress color="secondary" /> : <CheckCircleOutlineIcon fontSize='large' color="secondary" /> : <button
                type="submit"
                name="submit"
                id="submit"
                value="Submit"
                className="btn btn-default"
              >
                Send Message
              </button>}
            </form>
            {handleAlerts()}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
