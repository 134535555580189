import React, { useEffect, useState, useRef } from "react";
import firebase from 'firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageCard from "../components/elements/PageCard";
import Pagetitle from "../components/elements/Pagetitle";
import NewPageDialog from "../components/elements/NewPageDialog";
import UserBar from "../components/elements/UserBar";
import AddIcon from '@material-ui/icons/Add';
import { defaultData } from '../defaultData';
import AlertDialogSlide from '../components/elements/Alert';
import { Button, Grid } from "@material-ui/core";
import { Delete as DeleteIcon } from '@material-ui/icons';


const colors = ["#6C6CE5", "#F9D74C", "#F97B8B", "#F9D74C", "#F97B8B"];

function Admin({ user }) {

    const isInitialMount = useRef(true);
    const [isLoading, setIsLoading] = useState(true)
    const firestore = firebase.firestore();
    const pagesRef = firestore.collection('pages');
    let query = user ? pagesRef.where('userId', '==', user.uid) : pagesRef.where('userId', '==', process.env.REACT_APP_FIREBASE_USER_ID);
    const [pages] = useCollectionData(query);
    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then((user) => {
        });
    }

    const signOutWithGoogle = () => {
        firebase.auth().signOut().then(function () {
            console.log('signed out');
        }, function (error) {
            console.log('error logging out');
        });
    }
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            setIsLoading(false);
        }
    });

    const deletePage = (pageId) => {
        pagesRef.where('pageId', '==', pageId).get().then((results) => {
            if (results.docs && results.docs.length > 0) {
                results.docs[0].ref.delete().then(() => {
                    console.log('document deleted');
                });
            }
        })
    }

    const [open, setOpen] = useState(false);
    const [pageId, setPageId] = useState();

    const handleClickOpen = (e) => {
        setPageId(e.currentTarget.value)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');

    const handleClickOpenDialog = (e) => {
        e.preventDefault();
        setOpenDialog(true);
    };

    const handleCloseDialog = (value) => {
        setOpenDialog(false);
        setSelectedValue(value);
    };

    useEffect(() => {
        if (selectedValue) window.location.href = `/submit?page=${selectedValue}`
    }, [selectedValue])

    return (
        <div style={{ margin: 10 }}>
            <NewPageDialog selectedValue={selectedValue} open={openDialog} onClose={handleCloseDialog} />
            <AlertDialogSlide handleClose={handleClose} open={open} deletePage={deletePage} pageId={pageId} />
            <Helmet>
                <title>Onlinepage.es - Page Creator</title>
            </Helmet>
            {!isLoading ? user ?
                <>
                    <UserBar user={user} signOutWithGoogle={signOutWithGoogle} />
                    <section style={{ paddingTop: '10px' }}>
                        <div className="container">
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                            >

                                <Pagetitle title="Pages" />
                                <a className='btn btn-default mt-1' href='/submit' onClick={handleClickOpenDialog}>
                                    Create New Page +
                            </a>
                            </Grid>
                            <div className="row fix-spacing">
                                {pages && pages.map((page, i) => {
                                    const card = {
                                        title: `onlinepag.es/${page.id}`,
                                        icon: page.profileImage,
                                        color: colors[i % 5],
                                        contentColor: "light",
                                        pageId: page.pageId
                                    }
                                    return <div key={page.id} className="col-md-4">
                                        <PageCard card={card} handleClickOpen={handleClickOpen} />
                                    </div>
                                })}
                            </div>
                        </div>
                    </section>
                </>
                :
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '90vh',
                    }}>
                    <div
                        className="rounded data-background padding-30 text-center shadow-blue"
                        data-color="#6C6CE5"
                    >
                        <img src={defaultData.profileImage} alt={'profile img'} width='108px' />
                        <h3 className="mb-3 mt-2 text-center">It appears you're not logged in</h3>
                        <button onClick={signInWithGoogle} className='btn btn-default mt-1'>
                            Click here to Sign in \ up
                        </button>
                    </div>
                </div>
                : null
            }
        </div >
    );
};

export default Admin;
