import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";


function Experiences({ data }) {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title={data.experienceTitle} />
        <div>
          <div>
            <div >
              <div className="spacer d-md-none d-lg-none" data-height="30"></div>
              <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
                {data.experienceData.map((experience) => (
                  <Timeline key={experience.id} education={experience} />
                ))}
                <span className="line"></span>
              </div>
            </div>
            <br />
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {data.educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
