import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

function Blog({ blogData, data }) {
  const { id, category, title, date, author, image, filesource } = blogData;
  const getNospaceTitle = (filesource) => {
    let tempArr = filesource.split("/");
    let fileName = tempArr[tempArr.length - 1];
    let getName = fileName.slice(0, -3);
    return getName;
  };

  const getPostTitle = (url) => {
    try{
      let pathArr = url.split('sumbli.com/');
      url = pathArr[1];
      let urlParts = url.split('/');
      return urlParts[2];
    }
    catch (e) {
      return ''
    }
  };

  const getPostId = (url) => {
    try{
      let pathArr = url.split('sumbli.com/');
      url = pathArr[1];
      let urlParts = url.split('/');
      return urlParts[1];
    }
    catch (e) {
      return ''
    }

  };

  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      animateOnce={true}
    >
      <div className="blog-item rounded bg-white shadow-dark">
        <div className="thumb">
          {/*<a href="!#">*/}
          {/*  <span className="category">{category}</span>*/}
          {/*</a>*/}
          {/*<Link*/}
          {/*    // target={(blogData && blogData.isBlogExternal === 'true') ? '_blank' : ''}*/}
          {/*//       to={((blogData && blogData.isBlogExternal)  === 'true' ?*/}
          {/*//           blogData.filesource*/}
          {/*//    : (data && data.id && data.id.length) > 0) ?*/}
          {/*//   `/${data.id}/posts/${getPostId(filesource)}/${getPostTitle(filesource)}` :*/}
          {/*//   `/submit/posts/${getPostId(filesource)}/${getPostTitle(filesource)}`*/}
          {/*// }*/}
          {/*>*/}
          {/*</Link>*/}
          {(blogData && blogData.isBlogExternal  === 'true') ?
              <a href={blogData.filesource} target='_blank'>
                <img src={image} alt="blog-title" />
              </a>
              :
              <Link
                  target={(blogData && blogData.isBlogExternal === 'true') ? '_blank' : ''}
                  to={(blogData && blogData.isBlogExternal)  === 'true' ?
                      blogData.filesource
                      : (data && data.id && data.id.length) > 0 ?
                          `/${data.id}/posts/${getPostId(filesource)}/${getPostTitle(filesource)}` :
                          `/submit/posts/${getPostId(filesource)}/${getPostTitle(filesource)}`
                  }
              >
                <img src={image} alt="blog-title" />
              </Link>
          }
        </div>
        <div className="details">
          <h4 className="my-0 title">
            {(blogData && blogData.isBlogExternal  === 'true') ?
                <a href={blogData.filesource} target='_blank'>
                  {title}
                </a>
            :
                <Link
                    target={(blogData && blogData.isBlogExternal === 'true') ? '_blank' : ''}
                    to={(blogData && blogData.isBlogExternal)  === 'true' ?
                        blogData.filesource
                        : (data && data.id && data.id.length) > 0 ?
                            `/${data.id}/posts/${getPostId(filesource)}/${getPostTitle(filesource)}` :
                            `/submit/posts/${getPostId(filesource)}/${getPostTitle(filesource)}`
                    }
                >
                  {title}
                </Link>
            }

          </h4>
          <ul className="list-inline meta mb-0 mt-2">
            <li className="list-inline-item">{date}</li>
            <li className="list-inline-item">{author}</li>
          </ul>
        </div>
      </div>
    </ScrollAnimation>
  );
}

export default Blog;
