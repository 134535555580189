import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal({ open, handleClose, modalImage }) {

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent style={{ margin: 0, padding: 0, overflowY: 'hidden' }}>
                    <img className='unselectable' style={{ padding: '20px' }} src={modalImage} height='100%' width='auto' alt='' />
                </DialogContent>
            </Dialog>
        </div>
    );
}
