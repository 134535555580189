import React from "react";
import { AppBar, Grid, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Logo from '../../assets/top-bar-logo-red.svg'

function TopBar(props) {

    const { firebase, user, loading } = props;

    const signInWithGoogle = (e) => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then((user) => {
            window.location.href = '/admin'
        });
    }

    const handleText = () => {
        if (loading) return '';
        if (user) {
            if (window.location.pathname === '/admin') {
                return 'Log Out'
            } else {
                return 'Manage My Pages'
            }
        } else {
            return 'Sign In / Up'
        }
    }

    const handleClick = () => {
        if (user) {
            if (window.location.pathname === '/admin') {
                signOutWithGoogle();
            } else {
                window.location.href = '/admin'
            }
        } else {
            signInWithGoogle();
        }

    }

    const signOutWithGoogle = () => {
        firebase.auth().signOut().then(function () {
        }, function (error) {
            console.log('error logging out');
        });
        window.location.href = '/'
    }

    return (
        <>
            <AppBar elevation={props.editor && 0} position="static" style={{ backgroundColor: '#353353' }}>
                <Toolbar style={{ width: '100%' }}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Link to='/' style={{ color: '#ffffff' }} variant="h6" >
                            <img src={Logo} width={150} />
                        </Link>
                        {!props.editor && <Button onClick={handleClick} style={{ outline: 'none', color: 'white', textTransform: 'none' }}>{handleText()}</Button>}
                    </Grid>
                </Toolbar>
            </AppBar>
            <div style={{ minHeight: '100vh' }} >
                {props.children}
            </div>
            <div style={{ backgroundColor: '#353353', color: '#ffffff', padding: 10 }}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Link style={{ color: '#ffffff' }} to='/' variant="h6" >
                        <img src={Logo} width={150} />
                    </Link>
                    {'   '}
                    <Typography variant='body2'>
                        <Link to='/terms' style={{ textDecoration: 'underline', color: '#ffffff' }} >
                            Terms of Service
                    </Link>
                        {', '}
                        <Link to='/contact' style={{ textDecoration: 'underline', color: '#ffffff' }} >
                            Contact
                    </Link>
                        {', '}
                        <Link to='/privacy' style={{ color: '#ffffff', textDecoration: 'underline' }} >
                            Privacy Policy
                    </Link>
                        {', '}
                        {new Date().getFullYear()} Onlinepag.es, All rights reserved.
                    </Typography>
                </Grid>
            </div>
        </>
    );
}

export default TopBar;
