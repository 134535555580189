import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import YouTube from "react-youtube";


function Jobs(props) {
  const [getAllItems] = useState(props.data.works.sort(function (a, b) {
    return a.id - b.id;
  }));
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [choosenWork, setChoosenWork] = React.useState();
  const [existingFilters, SetExistingFilters] = useState(Array.from(new Set(['Everything', ...getAllItems.map(item => item.category)])))

  const handleOpenDialog = (work) => {
    setOpenDialog(true);
    setChoosenWork(work)
  };

  useEffect(() => {
    SetExistingFilters(Array.from(new Set(['Everything', ...getAllItems.map(item => item.category)])))
  }, [props.data])

  const handleClose = () => {
    setOpenDialog(false);
  };

  const youtubeParser = url => {
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
  }


  useEffect(() => {
    setActiveFilter(existingFilters[0].toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
  }, [dataVisibleCount, getAllItems, existingFilters]);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (e.target.textContent.toLowerCase() === existingFilters[0].toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) =>
          data.category === e.target.textContent &&
          data.id <= dataVisibleCount
      );
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === existingFilters[0].toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter(
            (data) => data.category === activeFilter && data.id <= tempCount
          )
        );
      }
    }
  };

  return (
    <section id="works">
      <div className="container">
        <Pagetitle title={props.data.portfolioTitle} />
        {/* Start Portfolio Filters */}
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <ul className="portfolio-filter list-inline">
            {existingFilters.map((filter, i) => (
              <li className="list-inline-item" key={i}>
                <button
                  onClick={handleChange}
                  className={
                    (filter && filter.toLowerCase() === activeFilter)
                      ? "text-capitalize current"
                      : "text-capitalize"
                  }
                >
                  {filter}
                </button>
              </li>
            ))}
          </ul>
        </ScrollAnimation>
        {/* End Portfolio Filters */}

        {/* Start Portfolio Items */}
        <div className="row portfolio-wrapper">
          {visibleItems.map((item, i) => (
            <div key={i} onClick={() => handleOpenDialog(item)} className="col-md-4 col-sm-6 grid-item" key={item.id}>
              <Portfolio portfolio={item} />
            </div>
          ))}
        </div>
        {/* End Portfolio Items */}

        <div className="load-more text-center mt-4">
          <button
            className="btn btn-default"
            onClick={handleLoadmore}
            disabled={noMorePost ? "disabled" : null}
          >
            {noMorePost ? (
              "No more items"
            ) : (
                <span>
                  <i className="fas fa-spinner"></i> Load more
                </span>
              )}
          </button>
        </div>
      </div>
      {choosenWork &&
        <Dialog
          open={openDialog}
          onClose={handleClose}
          scroll={'paper'}
          disableScrollLock
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">{choosenWork.title}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText
              id="scroll-dialog-description"
              // ref={descriptionElementRef}
              tabIndex={-1}
            >
              <img src={choosenWork.image} /><br />
              {choosenWork.video &&
                <div className="video-container">
                  <iframe width="560" height="315" src={"https://www.youtube.com/embed/" + youtubeParser(choosenWork.video)}
                    frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />
                </div>
              }
              {choosenWork.text}
              <br />
              <a target='_blank' rel='noopener noreferrer' href={choosenWork.link}>{choosenWork.linkText}</a>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              close
            </Button>
          </DialogActions>
        </Dialog>
      }
    </section>
  );
}

export default Jobs;
