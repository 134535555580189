import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";
import ScrollAnimation from "react-animate-on-scroll";


function Services({ data }) {
  return (
    <section id="services">
      <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
      >
      <div className="container">
        <Pagetitle title={data.servicesTitle} />
        <div className="row fix-spacing" style={{ justifyContent: 'space-around' }}>
          {data.servicesData.map((service) => (
            <div className="col-md-4" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            Looking for a custom job?{" "}
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Click here
            </Link>{" "}
            to contact me! 👋
          </p>
        </div>
      </div>
      </ScrollAnimation>
    </section>
  );
}

export default Services;
