import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Grid, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert'
import firebase from "firebase";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
function Subscribe({ data }) {
    const [formdata, setFormdata] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });
    const firestore = firebase.firestore();
    const subscriptionsRef = firestore.collection("subscriptions");
  

    const [openSnack, setOpenSnack] = useState(false);
    const [sending, setSending] = useState(0);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");

    const submitHandler = (event) => {
        event.preventDefault();
       if (!formdata.email) {
            setError(true);
            setMessage("Email is required");
        } else {
            sendMail()
        }
    };

    const handleChange = (event) => {
        setFormdata({
            ...formdata,
            [event.currentTarget.name]: event.currentTarget.value,
        });
    };

    const handleAlerts = () => {
        if (error && message) {
            return <div className="alert alert-danger mt-4">{message}</div>;
        } else if (!error && message) {
            return <div className="alert alert-success mt-4">{message}</div>;
        } else {
            return null;
        }
    };

    const sendMail = () => {
        subscriptionsRef.add({url: data.id, email: formdata.email}).then((response) => {            
           setOpenSnack(true); 
        });
    }

    return (
        <section id="subscribe" style={{ paddingBottom: '156px', paddingTop: '156px' }}>
            <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
            >
                <div className="container">
                    <Pagetitle title={data.subscribeTitle} />
                    <div className="row">
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >
                                {data.subscribeText && <p>
                                    {data.subscribeText}
                            </p>}
                                <p>
                                    {/* <a href="http://maps.google.com/?q=1200 Ness Ziona , Israel Heiman 4, Israel">...</a> */}
                                </p>
                            </ScrollAnimation>

                            <form
                                id="contact-form"
                                className="contact-form mt-6"
                                onSubmit={submitHandler}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                >
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="InputEmail"
                                        name="email"
                                        placeholder="Email address"
                                        onChange={handleChange}
                                        value={formdata.email}
                                    />
                                    {sending ? sending === 1 ? <CircularProgress color="secondary" /> : <CheckCircleOutlineIcon fontSize='large' color="secondary" /> : <button
                                        type="submit"
                                        name="submit"
                                        id="submit"
                                        value="Submit"
                                        className="btn btn-default"
                                        style={{ marginTop: 20 }}
                                    >
                                        Subscribe
                            </button>}
                                </Grid>
                            </form>


                        </Grid>

                        <div className="col-md-8">

                            {handleAlerts()}
                        </div>
                    </div>
                </div>
            </ScrollAnimation>

            <Snackbar open={openSnack} onClose={()=>{setOpenSnack(false)}} autoHideDuration={6000} >
                <Alert severity="success">Subscription saved!</Alert>
            </Snackbar>
        </section>
    );
}

export default Subscribe;
