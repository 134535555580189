import React from "react";
import TrackVisibility from "react-on-screen";
import Pagetitle from "../elements/Pagetitle";
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    FacebookMessengerShareButton,
    WorkplaceShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";
import {Grid} from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";


function Share({ data }) {
    return (
        <section style={{marginTop: 0, paddingTop: 0}} id="share">
            <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
            >
            <div className="container">
                <Pagetitle title={data.shareTitle} />
                <div className="row">
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <WhatsappShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <WhatsappIcon
                                        size={32}
                                        round />
                                </WhatsappShareButton>
                                <EmailShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <EmailIcon
                                        size={32}
                                        round />
                                </EmailShareButton>
                                <FacebookMessengerShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <FacebookMessengerIcon
                                        size={32}
                                        round />
                                </FacebookMessengerShareButton>
                                <FacebookShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <FacebookIcon
                                        size={32}
                                        round />
                                </FacebookShareButton>

                                {/*<HatenaShareButton*/}
                                {/*    // url={data.facebookUrl}*/}
                                {/*    // quote={data.title}*/}
                                {/*    className="Demo__some-network__share-button">*/}
                                {/*    <HatenaIcon*/}
                                {/*        size={32}*/}
                                {/*        round />*/}
                                {/*</HatenaShareButton>*/}
                                {/*<InstapaperShareButton*/}
                                {/*    // url={data.facebookUrl}*/}
                                {/*    // quote={data.title}*/}
                                {/*    className="Demo__some-network__share-button">*/}
                                {/*    <InstapaperIcon*/}
                                {/*        size={32}*/}
                                {/*        round />*/}
                                {/*</InstapaperShareButton>*/}
                                <LineShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <LineIcon
                                        size={32}
                                        round />
                                </LineShareButton>
                                <TwitterShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <TwitterIcon
                                        size={32}
                                        round />
                                </TwitterShareButton>
                                <LinkedinShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <LinkedinIcon
                                        size={32}
                                        round />
                                </LinkedinShareButton>
                                <RedditShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <RedditIcon
                                        size={32}
                                        round />
                                </RedditShareButton>

                                <PinterestShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <PinterestIcon
                                        size={32}
                                        round />
                                </PinterestShareButton>
                                {/*<LivejournalShareButton*/}
                                {/*    // url={data.facebookUrl}*/}
                                {/*    // quote={data.title}*/}
                                {/*    className="Demo__some-network__share-button">*/}
                                {/*    <LivejournalIcon*/}
                                {/*        size={32}*/}
                                {/*        round />*/}
                                {/*</LivejournalShareButton>*/}

                                {/*<PocketShareButton*/}
                                {/*    // url={data.facebookUrl}*/}
                                {/*    // quote={data.title}*/}
                                {/*    className="Demo__some-network__share-button">*/}
                                {/*    <PocketIcon*/}
                                {/*        size={32}*/}
                                {/*        round />*/}
                                {/*</PocketShareButton>*/}

                                <TelegramShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <TelegramIcon
                                        size={32}
                                        round />
                                </TelegramShareButton>
                                {/*<TumblrShareButton*/}
                                {/*    // url={data.facebookUrl}*/}
                                {/*    // quote={data.title}*/}
                                {/*    className="Demo__some-network__share-button">*/}
                                {/*    <TumblrIcon*/}
                                {/*        size={32}*/}
                                {/*        round />*/}
                                {/*</TumblrShareButton>*/}

                                <ViberShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <ViberIcon
                                        size={32}
                                        round />
                                </ViberShareButton>
                                <MailruShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <MailruIcon
                                        size={32}
                                        round />
                                </MailruShareButton>
                                <OKShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <OKIcon
                                        size={32}
                                        round />
                                </OKShareButton>

                                <VKShareButton
                                    url={data.shareUrl}
                                    quote={data.pageTitle}
                                    className="share-buttons">
                                    <VKIcon
                                        size={32}
                                        round />
                                </VKShareButton>

                                {/*<WorkplaceShareButton*/}
                                {/*    // url={data.facebookUrl}*/}
                                {/*    // quote={data.title}*/}
                                {/*    className="Demo__some-network__share-button">*/}
                                {/*    <WorkplaceIcon*/}
                                {/*        size={32}*/}
                                {/*        round />*/}
                                {/*</WorkplaceShareButton>*/}
                            </Grid>

                </div>
                <div className="spacer" data-height="40"></div>
            </div>
            </ScrollAnimation>
        </section>
    );
}

export default Share;
