import React from "react";
import { Button, Typography } from "@material-ui/core";
import { Delete as DeleteIcon } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import firebase from "firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function PageCard({ card, handleClickOpen }) {
  const { title, icon, color, contentColor, pageId } = card;
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState('');
  const firestore = firebase.firestore();
  const subscriptionsRef = firestore.collection("subscriptions");
  let query = subscriptionsRef.where(
    "url",
    "==",
    id
  );
  
  const [subscriptions] = useCollectionData(query);

  const hexToRgb = (hex) => {
    const temp = hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
    return temp.join(",");
  };

  function openSubscribers(id){
    setOpen(true);
    setId(id)
  }
  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div
      className={
        contentColor === "light"
          ? "service-box rounded data-background padding-30 text-center text-light shadow-blue"
          : "service-box rounded data-background padding-30 text-center shadow-blue"
      }
      data-color="#6C6CE5"
      style={{
        background: color,
        boxShadow: `0px 5px 20px 0px rgba(${hexToRgb(color)}, 0.5)`,
        width: '100%',
        height: '100%'
      }}
    >
      <img src={icon} alt={title} style={{ height: '108px' }} />
      <h3 style={{width: '100%', wordWrap: 'break-word'}} className="mb-3 mt-0">{title}</h3>
      <a className='btn btn-default mt-1' href={title.split('/')[1]}>
        View Page
      </a>
      <br />
      <a href={`${title.split('/')[1]}/edit`} className='text-light'>Edit Page</a>
      <br />
      <a style={{ color: 'whitesmoke', cursor: 'pointer' }} autoCapitalize={false} onClick={(e)=>{openSubscribers(title.split('/')[1])}}>View Subscribers</a>
      <br />
      <Button value={pageId} onClick={handleClickOpen}><DeleteIcon style={{ color: 'whitesmoke' }} /></Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Subscribers"}</DialogTitle>
        <DialogContent>
          {(subscriptions && subscriptions.length > 0) ? subscriptions.map((value)=>{
            return (
                <Typography>{value.email}</Typography>
            );
          })
        :
        <Typography>No Subscribers</Typography>
        }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default PageCard;
