import React, { useState } from "react";
import Pagetitle from "../elements/Pagetitle";
import Picture from "../elements/Picture";
import Modal from "../elements/Modal";



function Gallery({ data }) {

    const [open, setOpen] = useState(false);
    const [modalImage, setModalImage] = useState('');

    const handleClickOpen = (image) => {
        setModalImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setModalImage('');
        setOpen(false);
    };

    return (
        <section id="gallery" >
            <Modal open={open} modalImage={modalImage} handleClickOpen={handleClickOpen} handleClose={handleClose} />
            <div className="container">
                <Pagetitle title={data.galleryTitle} />
                <div className="gallery-grid">
                    {data.galleryData.map((picture) => (
                        <Picture key={picture.id} picture={picture} handleClickOpen={handleClickOpen} />
                    ))}
                </div>
            </div>

        </section>
    );
}

export default Gallery;
