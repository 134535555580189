import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import Logo from "../elements/Logo";

const nameToShown = {
  landing: 'isLandingShown',
  home: 'isHeaderShown',
  about: 'isAboutShown',
  services: 'isServicesShown',
  experiences: 'isExperienceShown',
  works: 'isPortfolioShown',
  pricing: 'isPricingShown',
  testimonials: 'isTestimonialShown',
  posts: 'isBlogShown',
  gallery: 'isGalleryShown',
  contact: 'isContactShown',
  subscribe: 'isSubscribeShown',
  share: 'isShareShown',
  support: 'isSupportShown',
  links: 'isLinksShown'
}

function Header({ editorToggle, light, isBlog, data, toggleMenu, headerToggler, toggleEditor, isEditor }) {
  let sectionOrder;
  if (data) {
    sectionOrder = data.sectionOrder
  }
  const handleClasses = () => {
    let classes = `desktop-header-1 d-flex align-items-start flex-column ${isEditor && toggleEditor && 'menu-hidden'}`;
    if (light & toggleMenu) {
      classes += " light open";
    } else if (toggleMenu) {
      classes += " open";
    } else if (light) {
      classes += " light";
    }
    return classes;
  };
  const handleMobileClasses = () => {
    let classes = "mobile-header-1";
    if (light & toggleMenu) {
      classes += " light open";
    } else if (toggleMenu) {
      classes += " open";
    } else if (light) {
      classes += " light";
    }
    return classes;
  };

  const [navbar, setNavbar] = useState();

  useEffect(() => {
    if (sectionOrder && sectionOrder.length > 0) {
      const orderedNavbar = sectionOrder.map((sectionName, index) => {
        if (sectionName === 'brandlogos') return null;
        if (!data[nameToShown[sectionName]]) return null;
        return (
          <React.Fragment key={index}>
            {!isBlog &&
              <li>
                <Link
                  activeClass="active"
                  to={`section-${sectionName}`}
                  spy={true}
                  smooth='true'
                  duration={500}
                >
                  {whichSection(sectionName)}
                </Link>
              </li>
            }
          </React.Fragment>
        );
      })
      setNavbar(orderedNavbar);
    }
  }, [sectionOrder, data])

  const whichSection = (sectionName) => {
    switch (sectionName) {
      case 'landing':
        return data.isLandingShown &&
          <><i className="icon-plane"></i>Landing</>
      case 'home':
        return data.isHeaderShown &&
          <><i className="icon-home"></i>Home</>
      case 'support':
        return data.isSupportShown &&
          <><i className="icon-support"></i>Support</>
      case 'links':
        return data.isLinksShown &&
          <><i className="icon-link"></i>Links</>
      case 'about':
        return data.isAboutShown &&
          <><i className="icon-user-following"></i>About</>
      case 'services':
        return data.isServicesShown &&
          <><i className="icon-briefcase"></i>Services</>
      case 'experiences':
        return data.isExperienceShown &&
          <><i className="icon-graduation"></i>Experience</>
      case 'works':
        return data.isPortfolioShown &&
          <><i className="icon-layers"></i>Portfolio</>
      case 'pricing':
        return data.isPricingShown &&
          <><i className="icon-wallet"></i>Pricing</>
      case 'testimonials':
        return data.isTestimonialShown &&
          <><i className="icon-speech"></i>Reviews</>
      case 'posts':
        return data.isBlogShown &&
          <><i className="icon-note"></i>Posts</>
      case 'gallery':
        return data.isGalleryShown &&
          <><i className="icon-picture"></i>Gallery</>
      case 'contact':
        return data.isContactShown &&
          <><i className="icon-bubbles"></i>Contact</>
      case 'subscribe':
          return data.isSubscribeShown &&
            <><i className="icon-envelope"></i>Subscribe</>
      case 'share':
        return data.isShareShown &&
          <><i className="icon-share"></i>Share</>
      default:
        return null;
    }
  }

  return (
    <>
      <header className={handleMobileClasses()}>
        <div className="container">
          <div className="menu-icon d-inline-flex mr-4">
            <button onClick={headerToggler}>
              <span></span>
            </button>
          </div>
          <Logo isBlog logoSource={data ? data.pageTitle : ''} />
          {isEditor && <i className='icon-pencil editor-button-mobile' onClick={editorToggle} />}
        </div>
      </header>
      <header className={handleClasses()}>
        <Logo isBlog logoSource={data ? data.pageTitle : ''} />
        <nav>
          <ul className="vertical-menu scrollspy">
            {navbar}
          </ul>
        </nav>

        <div className="footer">
          <span className="copyright">
            &copy; {new Date().getFullYear()}  Powered by <a className='nohoverlink' href='https://onlinepag.es/'>onlinepag.es</a></span>
        </div>
      </header>
    </>
  );
}

export default Header;
