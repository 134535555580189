import React from "react";
import firebase from 'firebase';
import { Helmet } from "react-helmet";
import Landing from "../components/sections/Landing";
import { defaultData } from '../defaultData';
import {AppBar, Grid, Toolbar} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";


function Home({ user }) {
  // const signInWithGoogle = (newData) => {
  //   const provider = new firebase.auth.GoogleAuthProvider();
  //   firebase.auth().signInWithPopup(provider).then((user) => {

  //   });
  // }
  return (
    <>
      <Helmet>
        <title>Onlinepage.es - Page Maker</title>
      </Helmet>

        <Landing data={defaultData} manage={user ? 'Manage My Pages' : 'Create a Website'} />

    </>
  );
}

export default Home;
