import React from "react";

function UserBar({ user, signOutWithGoogle }) {

    function logOut(e) {
        e.preventDefault();
        signOutWithGoogle();
    }

    return (
        <div>
            <div className="row fix-spacing m-2">
                <img src={user.photoURL} alt='user' className="rounded-circle" />
                <div className="ml-2">
                    <div className="col" >
                        <h3 className='mb-0'>{user.displayName}</h3>
                        {/* <a href="" onClick={logOut}>Log Out</a> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserBar;
