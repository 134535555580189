import React, { useState, useEffect, useLayoutEffect } from "react";
// import ReactMarkdown from "react-markdown/with-html";
import axios from "axios";
// import Disqus from "disqus-react";
import Header from "../components/layouts/Header";
import firebase from "firebase";
import { useParams } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { defaultData } from "../defaultData";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}


function BlogDetails(props) {
  const [content, setContent] = useState("");
  // const blogId = props.match.params.id;
  const blogFile = props.match.params.title;
  const [width, height] = useWindowSize();
  const [data, setData] = useState();


  const firestore = firebase.firestore();
  const pagesRef = firestore.collection('pages');
  let { profileId } = useParams();
  let query = pagesRef.where('id', '==', profileId ? profileId : process.env.REACT_APP_FIREBASE_PAGE_ID);
  const [pages] = useCollectionData(query);


  useEffect(() => {
    if (pages && pages[0] && pages[0].allBlogs && profileId === 'submit') {
      setData(pages[0])
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = pages[0].favicon;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    else if (pages) {
      setData(defaultData);
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = pages[0].favicon;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }, [profileId, pages]);


  useEffect(() => {
    axios.get(require(`../assets/blogs/best-app-development-tool.md`)).then((result) => {
      setContent(result.data);
    });
  }, [content, blogFile]);

  // const disqusShortname = "bolby"; //found in your Disqus.com dashboard
  // const disqusConfig = {
  //   url: "https://jthemes.net/themes/react/bolby", //Page link of this site.
  //   identifier: blogId,
  //   title: blogFile,
  // };

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  // document.body.classList.add("dark");
  //Uncomment the above line if you use dark version

  return (
    <>
      <Header
        isBlog
        data={data}
        logoSource="Posts"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        {/*<div className="spacer" data-height="96"></div>*/}
        {/*<div className="blog-page-section">*/}
        {/*  <div className="container">*/}
        {/*    <div className="blog-single shadow-dark p-30">*/}
        {/*      <ReactMarkdown*/}
        {/*        source={content}*/}
        {/*        escapeHtml={false}*/}
        {/*      ></ReactMarkdown>*/}
        {/*      <div className="mi-blog-details-comments mt-30">*/}
        {/*        <Disqus.DiscussionEmbed*/}
        {/*          shortname={disqusShortname}*/}
        {/*          config={disqusConfig}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="spacer" data-height="96"></div>*/}
        <div style={{
          paddingLeft: width > 990 ? 290 : 0,
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: width > 990 ? 0 : 60
        }}>
          <iframe title={props.match.params.id} width="100%" height="100%" frameBorder="0" src={"https://sumbli.com/p/" + props.match.params.id + '/' + props.match.params.title + '/view'} />
        </div>
      </main>
    </>
  );
}

export default BlogDetails;
