import React from "react";
import TrackVisibility from "react-on-screen";
import Pagetitle from "../elements/Pagetitle";
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    FacebookMessengerShareButton,
    WorkplaceShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";
import { Grid } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";


function Links({ data }) {
    return (
        <section style={{ marginTop: 0, paddingTop: 0 }} id="links">
            <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
            >
                <div className="container">
                    <Pagetitle title={data.linksTitle} />
                    <div className="row">
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{paddingLeft: 10, paddingRight: 10}}
                        >

                            {data && data.bigLinks.map((link, i) => {
                                return (

                                    <a
                                        key={i}
                                        style={{ marginBottom: 15, maxWidth: 400, width: '100%' }}
                                        href={link.url}
                                        target='_blank'
                                        className="btn btn-default"
                                        spy={'true'}
                                        smooth={'true'}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <i className={(link.icon && link.icon.includes('icon-')) ? link.icon : ''} />
                                            <span>
                                                {link.title}
                                            </span>
                                            <span></span>
                                        </Grid>
                                    </a>
                                )
                            })}
                        </Grid>
                    </div>
                    <div className="spacer" data-height="40"></div>
                </div>
            </ScrollAnimation>
        </section>
    );
}

export default Links;
