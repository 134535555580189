import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Bloglist from "./containers/Bloglist";
import BlogDetails from "./containers/BlogDetails";
import Editor from "./containers/Editor";
import Home from "./containers/Home";
import Admin from "./containers/Admin";
import "./App.scss";
import firebase from 'firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect } from "react-router-dom";
import mixpanel from 'mixpanel-browser';
import NotFound from "./components/sections/NotFound";
import TopBar from "./components/elements/TopBar";
import Terms from "./containers/Terms";
import Privacy from "./containers/Privacy";
import Contact from "./containers/Contact";
import AddSection from "./containers/AddSection";

mixpanel.init(process.env.REACT_APP_MIXPANEL_INIT);

firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});
const auth = firebase.auth();


function App() {

    const [user, loading] = useAuthState(auth);
    const location = useLocation();
    useEffect(
        () => {
            mixpanel.track('pageview', { url: window.location.pathname })
        },
        [location]
    );
    return (
        <>
            <Switch>

                <Route path="/" exact>
                    <TopBar user={user} firebase={firebase} loading={loading}>
                        <Home user={user} />
                    </TopBar>
                </Route>

                <Route path="/404" exact>
                    <TopBar user={user} firebase={firebase} loading={loading}>
                        <NotFound />
                    </TopBar>
                </Route>

                <Route path="/submit" exact>
                    <Editor user={user} isEditor />
                </Route>

                <Route path="/admin" exact>
                    <TopBar user={user} firebase={firebase} loading={loading}>
                        {user ?
                            <Admin user={user} />
                            :
                            <Admin />
                        }
                    </TopBar>
                </Route>

                <Route path="/terms" exact>
                    <TopBar user={user} firebase={firebase} loading={loading}>

                        <Terms />
                    </TopBar>
                </Route>
                <Route path="/contact" exact>
                    <TopBar user={user} firebase={firebase} loading={loading}>

                        <Contact />
                    </TopBar>
                </Route>

                <Route path="/addSection" exact>
                    <TopBar user={user} firebase={firebase} loading={loading}>

                        <AddSection />
                    </TopBar>
                </Route>


                <Route path="/privacy" exact>
                    <TopBar user={user} firebase={firebase} loading={loading}>

                        <Privacy />
                    </TopBar>
                </Route>

                <Route path="/:profileId/posts/:id/:title" component={BlogDetails} />

                <Route path="/:profileId/posts" component={Bloglist} exact />

                <Route path="/:id/edit" exact>
                    <Editor user={user} isEditor />
                </Route>

                <Route path="/:id" exact>
                    <Editor user={user} />
                </Route>

                <Route>
                    <Redirect to='/404' />
                </Route>

            </Switch>
        </>
    );
}

export default App;
