import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

function Picture({ picture, handleClickOpen }) {
    const { image } = picture;

    return (
        <>
            <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
                className="gallery-img picture shadow-dark unselectable"
            >
                <div className="gallery-img picture shadow-dark unselectable" onClick={() => { handleClickOpen(image) }}>

                    <img src={image} alt="" />
                </div>
            </ScrollAnimation>
        </>
    );
}

export default Picture;
