import React from "react";
import { Link } from "react-router-dom";

function getHomeUrl(){
    let getUrl = window.location;
    return "/" + getUrl.pathname.split('/')[1];
}

function Logo({ logoSource, bootstrapNav,isBlog }) {
  if (bootstrapNav) {
    return (
      <Link to={isBlog ? getHomeUrl() : '#'} className="navbar-brand drop-line">
        <h3 style={{ margin: 0, padding: 0, position: 'relative', top: 5, color: '#ffffff' }}>{logoSource}</h3>
      </Link>
    );
  }

  return (
    <Link to={isBlog ? getHomeUrl() : '#'} className="navbar-brand drop-line">
      <h3 style={{ margin: 0, padding: 0, top: 5, position: 'relative', color: '#ffffff' }}>{logoSource}</h3>
    </Link>
  );
}

export default Logo;
